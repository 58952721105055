import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/uikit/IconAdd";
import React, {useEffect, useState} from "react";
import "../css/pageEquipage.css";
import {Sidebar} from '@consta/uikit/Sidebar';
import {TextField} from "@consta/uikit/TextField";
import GridPpkList from "./component/grid.ppk.list";
import ModalCliffView from "./component/modal.ppk.ppkView";
import {IconDocExport} from "@consta/uikit/IconDocExport";

const PagePPK = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [exportButtonClick, setExportButtonClick] = useState<boolean>(false)
    useEffect(() => {
        document.title = 'АСПО.Контроль | ППК';
    }, []);

    const handleModalClose = (value: boolean) => {
        setIsModalOpen(false);
        setIsUpdate(value)
        // setReturnedValue(value);
    };
    return (
        <>
            <Grid className="gridMain" cols="1" gap="xs">
                <GridItem>
                    {localStorage.getItem('username') !== 'admin' &&
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button label="Добавить обрыв"
                                    iconRight={IconAdd}
                                    onlyIcon size='s'
                                    onClick={() => setIsModalOpen(true)}
                                    style={{marginRight:'5px'}}
                            />

                            <Button label="Export to Excel"
                                    iconRight={IconDocExport}
                                    onlyIcon size='s'
                                    onClick={() => setExportButtonClick(!exportButtonClick)}/> {/* Кнопка для экспорта */}
                            <span id="eMessage"></span>
                        </div>
                    }
                </GridItem>
                <GridItem>
                    <GridPpkList isUpdate={isUpdate} isExport={exportButtonClick}/>
                </GridItem>
            </Grid>
            <Sidebar
                size={'1/3'}
                isOpen={isSidebarOpen}
                onClickOutside={() => setIsSidebarOpen(false)}
                onEsc={() => setIsSidebarOpen(false)}
            >
                <div style={{
                    width: '85%',
                    maxHeight: '40%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingTop: '30px',
                    paddingBottom: '15px'
                }}>
                </div>
                {/*<div style={{*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*}}>*/}
                {/*    <TextField*/}
                {/*        placeholder="Гос. номер"*/}
                {/*        form="roundClear"*/}
                {/*        size={'s'}*/}
                {/*        style={{*/}
                {/*            width: '100%', maxWidth: '180px', paddingLeft: '30px',*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <Button label="Добавить"*/}
                {/*            iconRight={IconAdd}*/}
                {/*            size='s'*/}
                {/*        // view='secondary'*/}
                {/*            form='brickRound'*/}
                {/*        // onClick={() => setIsModalOpen(true)}*/}
                {/*            style={{marginRight: '30px'}}/>*/}
                {/*</div>*/}
            </Sidebar>
            <ModalCliffView isOpen={isModalOpen}
                            onClose={handleModalClose}
                            props={null}
                            nodeData={null}/>
        </>
    )
}

export default PagePPK;