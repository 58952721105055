import moment from "moment";

export function level(time: string, depth: number, valuePpk:number) {
    const descent_total = moment.duration(time, 'minutes')
    const m15 = moment.duration(15, 'minutes')
    const m30 = moment.duration(30, 'minutes')
    if (valuePpk && depth < (valuePpk !== 1000 ? (valuePpk - 5) : (valuePpk - 105))) {
        return {name: 'Непроход', id: '4d6204a7-6f49-4c74-a6e8-ad741db0c59a'}
    }
    if (descent_total < m15) {
        return {name: 'Легкая', id: '9e636a3a-4baa-4593-b978-aadad3f65b80'}
    } else if (descent_total > m15 && descent_total < m30) {
        return {name: 'Средняя', id: '9fb5023b-6d3a-4633-abc6-4f5e50e5b5ab'}
    } else {
        return {name: 'Тяжелая', id: '4d6204a7-6f49-4c74-a6e8-ad741db0c32a'}
    }
}

// определение степени осложнения от аналаиза БСИ
export function bsiLeveltoGraph(valueBsi: any, valueLevel:any, valuePpk:number) {
    const lastLevel = level(valueBsi[valueBsi.length - 1]?.descent_total, valueBsi[valueBsi.length - 1]?.depth, valuePpk)
    if (valueBsi.length > 1) {
        if (lastLevel.name === 'Непроход') {
            return lastLevel
        } else {
            return {name: valueLevel.label, id: valueLevel.id}
        }
    } else {
        return lastLevel
    }
}