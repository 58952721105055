import React, {useState} from "react";
import {FileField} from '@consta/uikit/FileField';
import {AiOutlineImport} from "react-icons/ai";
import ModalGraphImportXLS from "../page/graph/component/modal.graph.importXLS";
import "./css/ico.css";

const ExcelUploader = () => {
    const [file, setFile] = useState(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setIsModalOpen(true)
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setFile(null)
    }

    return (
        <div>
            <ModalGraphImportXLS isOpen={isModalOpen}
                                 onClose={handleModalClose}
                                 props={file}
            />
            <FileField id="FileFieldWithIcon" accept=".xlsx" onChange={handleFileChange} key={file}>
                <AiOutlineImport className="blue-on-hover" />
            </FileField>
        </div>
    );
};

export default ExcelUploader;
