import {Button} from "@consta/uikit/Button";
import {Modal} from "@consta/uikit/Modal";
import React, {useEffect, useState} from "react";
import {read, utils} from "xlsx";
import axios from "axios";
import {API_GRAPH_PAYLOAD} from "../../../service/api.endpoint";
import {Grid, GridItem} from "@consta/uikit/Grid";
import '../tooltip.css'
import {ProgressStepBar} from "@consta/uikit/ProgressStepBar";
import {ProgressLine} from '@consta/uikit/ProgressLine';
import {IconCheck} from "@consta/uikit/IconCheck";
import {IconClose} from "@consta/uikit/IconClose";
import cookieCmp from "../../../component/cookie.cmp";
import {Text} from '@consta/uikit/Text';
import moment from "moment";

interface ModalComponentProps {
    isOpen: boolean;
    onClose: any;
    props: any
}

type StatusProgressBarType = 'normal' | 'success' | 'alert' | 'warning' | undefined;

type ItemProgressBarType = {
    label: string;
    tooltipContent?: string;
    point?: number;
    progress?: boolean;
    status?: StatusProgressBarType;
    lineStatus?: StatusProgressBarType;
};


// чтени эксель файла
async function xlsRead(props: Blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, {type: 'array'});

                const sheetNames = workbook.SheetNames;
                const jsonData: any = {};

                sheetNames.forEach((sheetName) => {
                    const worksheet = workbook.Sheets[sheetName];
                    jsonData[sheetName] = utils.sheet_to_json(worksheet, {header: 1});
                });
                resolve(jsonData);
            } catch (error) {
                reject(error);
            }
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
        fileReader.readAsArrayBuffer(props);
    });
}


// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

const ModalGraphImportXLS: React.FC<ModalComponentProps> = ({props, isOpen, onClose}) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    // дата из файла загрузки
    const [xlsDate, setXlsDate] = useState('')
    const [xlsHeaderCount, setXlsHeaderCount] = useState(0)
    const [jsonData, setJsonData] = useState<any>([])
    const [sbStatus, setSbStatus] = useState<StatusProgressBarType[]>(['normal', 'normal', 'normal'])
    const [sbLineStatus, setSbLineStatus] = useState<StatusProgressBarType[]>(['normal', 'normal', 'normal'])
    const [sbProgress1, setSbProgress1] = useState<boolean>(false)
    const [sbProgress2, setSbProgress2] = useState<boolean>(false)
    const [sbProgress3, setSbProgress3] = useState<boolean>(false)
    const [sbPoint1, setSbPoint1] = useState<any>(1)
    const [sbPoint2, setSbPoint2] = useState<any>(2)
    const [sbPoint3, setSbPoint3] = useState<any>(3)
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0)
    const [pgLineValue, setPgLineValue] = useState<number | undefined>(undefined)
    const [pgVisible, setPgVisible] = useState<boolean>(false)
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false)
    const [isFinishImport, setIsFinishImport] = useState<string | undefined>()
    const [isCloseDisabled, setIsCloseDisabled] = useState<boolean>(false)
    const [textError, setTextError] = useState<any>(null)


    const steps: ItemProgressBarType[] = [
        {
            label: 'Проверяем кусты',
            point: sbPoint1,
            progress: sbProgress1,
            status: sbStatus[0],
            lineStatus: sbLineStatus[0],
            tooltipContent: `Небольшое описание выполнения или состояния на шаге 1`,
        },
        {
            label: 'Проверяем скважины',
            point: sbPoint2,
            status: sbStatus[1],
            progress: sbProgress2,
            lineStatus: sbLineStatus[1],
            tooltipContent: `Небольшое описание выполнения или состояния на шаге 2`,
        },
        {
            label: 'Загрузка данных В БД',
            point: sbPoint3,
            status: sbStatus[2],
            progress: sbProgress3,
            lineStatus: sbLineStatus[2],
            tooltipContent: `Небольшое описание выполнения или состояния на шаге 3`,
        },

    ];
    // useEffect(() => {
    //     setModalOpen(isOpen);
    // }, [isOpen]);

    const handleCloseModal = () => {
        setModalOpen(false);
        onClose();
    };

    // Данные из экселя
    const getXlsRead = async () => {
        // Вспомогательная функция для проверки, является ли значение строкой
        const isString = (val: any): boolean => typeof val === 'string';

        // Вспомогательная функция для преобразования Excel-числа в дату
        const convertExcelDate = (excelNumber: number): string => {
            const baseDate = moment('1900-01-01');
            return baseDate.add(excelNumber - 2, 'days').format('DD.MM.YYYY');
        };

        // Вспомогательная функция для обработки листа Excel
        const processSheet = (sheetData: any) => {
            if (!sheetData || sheetData.length === 0) return;
            const firstCell = sheetData[0][0];
            if (isString(firstCell)) {
                setXlsDate(firstCell);
            } else {
                setXlsDate(convertExcelDate(firstCell));
            }
            setXlsHeaderCount(sheetData[8]?.length || 0);
        };

        // Основная логика функции
        try {
            const result: any = await xlsRead(props);
            setJsonData(result);

            const sheets = ['ВОСТОК', 'СЕВЕР', 'ЗАПАД', 'ЮГ', 'Салым'];
            for (const sheet of sheets) {
                if (result[sheet]) {
                    processSheet(result[sheet]);
                    break;
                }
            }
        } catch (error) {
            console.error('Error reading Excel file:', error);
        }
    };

    useEffect(() => {
        if (props) {
            getXlsRead()
        }
    }, [isOpen, props]);

    const handleUpload = async () => {
            const progressBarOperand = (element: number, lineStatus?: StatusProgressBarType, status?: StatusProgressBarType, activeStep?: number) => {
                // setSbProgress(prevState => progress ? prevState.map((value, index) => index === element ? progress : value) : prevState);
                // setSbLineStatus(prevState => lineStatus ? prevState.map((value, index) => index === element ? lineStatus : value) : prevState);
                // setSbStatus(prevState => status ? prevState.map((value, index) => index === element ? status : value) : prevState);
                // if (progress) {
                //     setSbProgress(prevProgress => {
                //         const updatedProgress = [...prevProgress]; // Создаем копию массива
                //         updatedProgress[element] = progress; // Обновляем значение в копии массива
                //         return updatedProgress; // Обновляем состояние sbProgress
                //     });
                // }
                if (lineStatus) {
                    setSbLineStatus(prevLineStatus => {
                        const updatedSbLineStatus = [...prevLineStatus]; // Создаем копию массива
                        updatedSbLineStatus[element] = lineStatus; // Обновляем значение в копии массива
                        return updatedSbLineStatus; // Обновляем состояние sbProgress
                    });
                }
                if (status) {
                    setSbStatus(prevStatus => {
                        const updatedSbStatus = [...prevStatus]; // Создаем копию массива
                        updatedSbStatus[element] = status; // Обновляем значение в копии массива
                        return updatedSbStatus; // Обновляем состояние sbProgress
                    });
                }
                if (activeStep) {
                    setActiveStepIndex(activeStep);
                }
            };

            if (props) {
                let result_bush;
                let result_wells;
                let errorCounter = 0;

                // разделяем массив данных для загрузки и обрезаем шапку
                // @ts-ignore
                const listArr = [{sheet: "ВОСТОК", data: jsonData['ВОСТОК']?.slice(9,)}, {
                    sheet: "СЕВЕР",
                    data: jsonData['СЕВЕР']?.slice(9,)
                }, {sheet: "ЗАПАД", data: jsonData['ЗАПАД']?.slice(9,)}, {
                    sheet: "ЮГ",
                    data: jsonData['ЮГ']?.slice(9,)
                }, {sheet: "Салым", data: jsonData['Салым']?.slice(9,)}]
                // debugger
                const splitLists: any[][] = [];
                listArr.forEach((list) => {
                    if (list.data) {
                        const chunkSize = Math.ceil(list.data.length / 5);
                        for (let i = 0; i < list.data.length; i += chunkSize) {
                            const chunk = list.data.slice(i, i + chunkSize);
                            chunk.unshift(xlsHeaderCount); // добавляем длину шапки таблицы для определения количества дней
                            chunk.unshift(xlsDate[0] !== "0" ? xlsDate[0] : xlsDate); // добавляем в начало списка дату графика
                            chunk.unshift(list.sheet); // добавляем индекс массива
                            splitLists.push(chunk);
                        }
                    }
                });
                try {
                    setIsSaveDisabled(true)
                    setIsCloseDisabled(true)
                    // ПРОВЕРКА КУСТОВ
                    setSbProgress1(true)
                    result_bush = await axios.post(API_GRAPH_PAYLOAD, {data: jsonData, action: 'check_bush'}, {
                        headers: {
                            'X-CSRFToken': csrftoken
                        }
                    });
                    setSbProgress1(false)


                    if (result_bush.data['status'] === 'success') {
                        // ПРОВЕРКА СКВАЖИН
                        setSbPoint1(IconCheck)
                        progressBarOperand(0, 'success', 'success', 1);
                        setSbProgress2(true)

                        result_wells = await axios.post(API_GRAPH_PAYLOAD, {data: jsonData, action: 'check_wells'}, {
                            headers: {
                                'X-CSRFToken': csrftoken
                            }
                        });
                        setSbProgress2(false)
                        if (result_wells.data['status'] === 'success') {
                            setPgVisible(true)
                            // загрузка данных
                            setSbPoint2(IconCheck)
                            progressBarOperand(1, 'success', 'success', 2);
                            setSbProgress3(true)
                            for (const arr of splitLists) {
                                const result_insert = await axios.post(API_GRAPH_PAYLOAD, {data: arr, action: 'insert'}, {
                                    headers: {
                                        'X-CSRFToken': csrftoken
                                    }
                                });
                                if (result_insert.data['status'] === 'success') {
                                    setPgLineValue(prevValue => (prevValue !== undefined ? prevValue + 1 : 0));
                                    // progressBarOperand(2, 'success', 'success', false, 2);
                                } else {
                                    // ошибка кустов
                                    setSbPoint1(IconClose)
                                    progressBarOperand(0, 'warning', 'warning', 0);
                                    errorCounter += 1;
                                }
                            }
                        } else {
                            // ошибка скважин
                            setTextError(result_wells.data.elements)
                            setSbPoint2(IconClose)
                            progressBarOperand(1, 'warning', 'warning', 1);
                            errorCounter += 1;
                        }
                    } else {
                        // ошибка кустов
                        setTextError(result_bush.data.elements)
                        errorCounter += 1;
                    }
                } catch (error) {
                    errorCounter += 1;
                }
                if (errorCounter === 0) {
                    const result_insert_prod = await axios.post(API_GRAPH_PAYLOAD, {data: null, action: 'insert_prod'}, {
                        headers: {
                            'X-CSRFToken': csrftoken
                        }
                    });
                    if (result_insert_prod.data['status'] === 'success') {
                        progressBarOperand(2, 'success', 'success', 2);
                        setSbProgress3(false)
                        setSbPoint3(IconCheck)
                        setIsFinishImport('success')
                        setIsCloseDisabled(false)
                    }
                    console.log("ВСЕ ГОТОВО БЕЗ ОШИБОК!")
                } else {
                    setIsCloseDisabled(false)
                    setIsFinishImport('error')

                    console.log("Исправьте ошибки ипать")
                }
            }
        }
    ;

    const renderTextErrors = () => {
        return (
            <>
                <Text weight='black'>Добавьте следующие {textError[0]['well'] ? 'скважины:' : 'кусты:'}</Text>
                {textError.map((error: any, index: number) => (

                    <Text
                        key={index}>м/р {error['deposit']} Куст {error['bush']} {error['well'] && `скважина ${error['well']}`}</Text>
                ))}
            </>
        );
    };


    return (
        <div>
            <Modal
                className='xlsImportModal'
                isOpen={isOpen}
                hasOverlay
                onEsc={onClose}
                onClose={handleCloseModal}
            >

                <Grid cols={4} gap='xs'>
                    <GridItem col={4}>
                        Импорт графика на {xlsDate}
                    </GridItem>
                    <GridItem col={4}>
                        {/*<Text as="p" size="m" view="primary">*/}
                        {/*    Выполните проверку импортируемого файла перед загрузкой.*/}
                        {/*</Text>*/}
                    </GridItem>
                    <GridItem col={4}>
                        <ProgressStepBar steps={steps} activeStepIndex={activeStepIndex}/>
                    </GridItem>
                    <GridItem col={4}>
                        {pgVisible && <ProgressLine
                            value={pgLineValue}
                            steps={['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']}
                            getItemLabel={(item) => item}
                        />}
                    </GridItem>
                    <GridItem col={4}>
                        {isFinishImport === 'success' ? "Импорт данных завершен успешно!" : isFinishImport === 'error' && renderTextErrors()}

                    </GridItem>
                    <GridItem col={4} style={{justifySelf: "end"}}>
                        <Button
                            size="m"
                            view="primary"
                            label="Загрузить"
                            width="default"
                            onClick={handleUpload}
                            disabled={isSaveDisabled}
                        />
                        <Button
                            className='button__cancel__import'
                            size="m"
                            view="primary"
                            label="Закрыть"
                            width="default"
                            onClick={onClose}
                            disabled={isCloseDisabled}
                        />

                    </GridItem>
                </Grid>

            </Modal>
        </div>
    );
}
export default ModalGraphImportXLS;