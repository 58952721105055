import {Item, ItemBsi} from "./modal.graph.newRecord";

interface PlanType {
    valuePlan?: number | null
    valueComment?: Item | null
    valueBsi?: ItemBsi[] | null
    valuePpk: string
    valueDepth: number
}

// признак план для картинки графика
export function plan(props: PlanType) {
    const ppk = parseInt(props.valuePpk)
    const comment = props.valueComment?.label
    const plan = props.valuePlan
    const bsi = props.valueBsi
    const depth = props.valueDepth

    // запланированные мероприятия
    if (plan && ((plan === 1) || (plan === 2) || (plan === 5))) {
        if (comment && comment !== 'ЛГПП' && comment !== 'ППК') {
            return 5
        }
        if (comment === 'ЛГПП') {
            return 2
        }

        if (comment === 'ППК' && bsi && ppk && bsi[bsi.length - 1].depth >= ppk) {
            return 2
        }

        if (bsi && ppk && bsi[bsi.length - 1].depth) {
            if (bsi[bsi.length - 1].depth < (ppk !== 1000 ? (ppk - 5) : (ppk - 105))) {
                return 5
            } else {
                return 2
            }
        } else {
            if (ppk && depth < (ppk !== 1000 ? (ppk - 5) : (ppk - 105))) {
                return 5
            } else {
                return 2
            }
        }
    } else {
        if (comment && comment !== 'ЛГПП' && comment !== 'ППК') {
            return 4
        }
        if (comment === 'ЛГПП') {
            return 3
        }
        if (comment === 'ППК' && bsi && ppk && bsi[bsi.length - 1].depth >= ppk) {
            return 3
        }
        if (bsi && ppk && bsi[bsi.length - 1].depth) {
            if (bsi[bsi.length - 1].depth < (ppk !== 1000 ? (ppk - 5) : (ppk - 105))) {
                return 4
            } else {
                return 3
            }
        } else {
            if (ppk && depth < (ppk !== 1000 ? (ppk - 5) : (ppk - 105))) {
                return 4
            } else {
                return 3
            }
        }
    }
}