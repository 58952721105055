const url = process.env.REACT_APP_IP_ADDRESS

export const API_CHECK_AUTH_URL = url + '/api/user/'
export const API_GET_USER_INFO = `${url}/api/user/info/`
export const API_DRAMATIQ_TASK_RESULT = `${url}/api/dramatiq/task_result`


export const API_LOGIN_URL = `${url}/api/login/`
export const API_TOKEN_REFRESH = `${url}/api/token/refresh`
export const API_TOKEN_OBTAIN = `${url}/api/token/obtain`
export const API_TOKEN_LOGOUT = `${url}/api/token/logout`

export const API_URL_DICT = `${url}/api/registry/dict`


export const API_URL_WELL = `${url}/api/registry/well`
export const API_URL = `${url}/api/registry/`


export const API_GRAPH_PAYLOAD = `${url}/api/graph/payload`
export const API_GRAPH_LIST = `${url}/api/graph/list`
export const API_GRAPH_DAY = `${url}/api/graph/day`
export const API_GRAPH_EXIST_MONTH = `${url}/api/graph/exist_month`
export const API_GRAPH_UPDATE = `${url}/api/graph/update`

export const API_EQUIPAGE_LIST = `${url}/api/equipage/list`
export const API_EQUIPAGE_REPAIR = `${url}/api/repair`
export const API_REPAIR_CHECK = `${url}/api/repair/check`
export const API_REPAIR_CHECK_DATE = `${url}/api/repair/date_check`
export const API_EQUIPAGE_REPAIR_UPDATE = `${url}/api/repair/update`


export const API_BSI_PAYLOAD = `${url}/api/bsi/payload`
export const API_BSI_LIST = `${url}/api/bsi/list`
export const API_BSI_DEL = `${url}/api/bsi/delete`
export const API_BSI_SIZE_CHECK = `${url}/api/bsi/size_check`
export const API_BSI_CLEAR_ID = `${url}/api/bsi/clear_id`
export const API_BSI_PROCESSING = `${url}/api/bsi/processing`
export const API_BSI_DIAGRAM = `${url}/api/bsi/diagram`
export const API_BSI_SELECT = `${url}/api/bsi/select`

export const API_REPORTS_REPORT1 = `${url}/api/reports/report1`
export const API_REPORTS_EVENT = `${url}/api/reports/event`

export const API_SETTINGS_BLOCKDATE = `${url}/api/settings/blockdate`

export const API_LOGGING_LIST = `${url}/api/logging/list`

export const API_CLIFF = `${url}/api/cliff`
export const API_CLIFF_GRAPH = `${url}/api/cliff/graph`


export const API_PPK = `${url}/api/ppk`
export const API_PPK_GRAPH = `${url}/api/ppk_graph`
export const API_PPK_ACT = `${url}/api/ppk_act`


export const API_DOWNLOAD_ACT = `${url}/api/download_ppk_act`
