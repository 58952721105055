import {Modal} from '@consta/uikit/Modal';
import {Text} from '@consta/uikit/Text';
import {SnackCmp} from "../../../component/snack.cmp";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {DatePicker} from "@consta/uikit/DatePicker";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Button} from "@consta/uikit/Button";
import React, {useEffect, useState} from "react";
// @ts-ignore
import _ from "lodash";
import axios from "axios";
import {
    API_EQUIPAGE_REPAIR,
    API_EQUIPAGE_REPAIR_UPDATE, API_REPAIR_CHECK_DATE,
    API_URL_DICT
} from "../../../service/api.endpoint";
import {company} from "../../../component/header.cmp";
import moment from "moment/moment";
import cookieCmp from '../../../component/cookie.cmp';

export type Item = {
    label: string;
    id: string;
};
type DictType = {
    pk: string,
    name: string,
    comment: { name: string, pk: string }[],
    level: { name: string, pk: string }[],
    equipage: { name: string, pk: string }[],
}

interface ModalComponentProps {
    isOpen: boolean;
    onClose: any;
    nodeData: any
    props: any
}

// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

const ModalEquipageNewRepair: React.FC<ModalComponentProps> = ({props, isOpen, onClose, nodeData}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [valueEquipage, setValueEquipage] = useState<Item | null>();
    const [valueComment, setValueComment] = useState<string | null>();
    const [valueFIO, setValueFIO] = useState<string | null>(null);
    const [itemsEquipage, setItemsEquipage] = useState<Item[]>([]);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [isAlert, setIsAlert] = useState<string | null>(null)
    const [alertText, setAlertText] = useState<string>('')
    const [checkRepairDateFrom, setCheckRepairDateFrom] = useState<any>(null)
    const [checkRepairDateTo, setCheckRepairDateTo] = useState<any>(null)


    const handleChangeFIO = ({value}: { value: string | null }) => setValueFIO(value);

    const handleSaveButton = async () => {
        setIsSaving(true); // Устанавливаем флаг сохранения в true
        try {
            await addRepair();
        } finally {
            setIsSaving(false);
        }
    };

    function clearData() {
        setValueEquipage(null)
        setValueFIO(null)
        setValueComment(null)
        setStartDate(null)
    }

    const handleChangeComment = ({value}: { value: string | null }) => setValueComment(value);

    const addRepair = async () => {
        const newData = {
            equipage: valueEquipage?.id,
            org: company,
            date_from: nodeData ? nodeData.data?.date_from : startDate && moment(startDate.toLocaleString("ru-RU", {timeZone: "Asia/Yekaterinburg"}),
                'DD.MM.YYYY, HH:mm:ss').format('YYYY-MM-DD'),
            date_to: nodeData && nodeData.data?.date_to ? nodeData.data.date_to : endDate && moment(endDate.toLocaleString("ru-RU", {timeZone: "Asia/Yekaterinburg"}),
                'DD.MM.YYYY, HH:mm:ss').format('YYYY-MM-DD'),
            comment: valueComment,
            fio: nodeData?.data?.fio ? nodeData.data.fio : valueFIO,
            username: localStorage.getItem('username')
        };
        if (nodeData?.data?.date_from) {
            try {
                const response = await axios.put(`${API_EQUIPAGE_REPAIR_UPDATE}/${nodeData.data.id}`, newData);
                // закрыть модальное окно
                setAlertText('Запись успешно добавлена!')
                setIsAlert('success');
                clearData()
                onClose(newData);
                console.log('Запись успешно добавлена:', response.data);
            } catch (error: any) {
                setAlertText('Ошибка при добавлении записи!')
                setIsAlert('alert');
                onClose(false);
                console.error('Ошибка при добавлении записи:', error.response.data);
            }
        } else {
            try {
                const response = await axios.post(`${API_EQUIPAGE_REPAIR}`, newData, {
                    headers: {
                        'X-CSRFToken': csrftoken
                    }
                });
                // закрыть модальное окно
                setAlertText('Запись успешно добавлена!')
                clearData()
                setIsAlert('success');
                onClose(newData);
                console.log('Запись успешно добавлена:', response.data);
            } catch (error: any) {
                setAlertText('Ошибка при добавлении записи!')
                setIsAlert('alert');
                onClose(false);
                console.error('Ошибка при добавлении записи:', error.response.data);
            }
        }
    };


    useEffect(() => {
        setIsAlert(null)
        axios
            .get<DictType>(API_URL_DICT)
            .then((response) => {
                const data: DictType = response.data;
                setItemsEquipage(data.equipage.filter((item: any) => item.org.name === company).map((item) => ({
                    label: item.name,
                    id: item.pk,
                })))
            });
    }, [])

    useEffect(() => {
        if (nodeData?.data?.comment) {
            setValueComment(nodeData.data.comment)
        }
    }, [nodeData, props?.collName]);

    useEffect(() => {
        if (!startDate || !endDate || !valueEquipage || !valueFIO || !valueComment || !checkRepairDateFrom || !checkRepairDateTo) {
            setIsSaving(true);
        } else {
            setIsSaving(false);
        }
    }, [startDate, endDate, valueEquipage, valueFIO, valueComment]);

    const handleDateChange = async (date: any, action: string) => {

        const formattedDate = moment(date).format('YYYY-MM-DD');

        const repairDateCheck = await axios.get(API_REPAIR_CHECK_DATE, {
            params: {
                date: formattedDate,
                equipage_id: valueEquipage?.id
            }
        });
        if (action === 'from') {
            setStartDate(date)
            setCheckRepairDateFrom(repairDateCheck.data)
        } else {
            setEndDate(date)
            setCheckRepairDateTo(repairDateCheck.data)
        }

    }

    const handleEquipageChange = (value: any) => {
        setValueEquipage(value)
        setStartDate(null)
        setEndDate(null)
    }


    return (
        <>
            <Modal
                className="modal"
                isOpen={isOpen}
                hasOverlay
                // onClickOutside={onClose}
                onEsc={onClose}
            >
                {isAlert === 'alert' && <SnackCmp alertType={isAlert} alertText={alertText}/>}
                <Grid cols="3" gap="xs">

                    {!nodeData?.data.equipage ?
                        <>
                            <GridItem col={"2"}>
                                <Combobox
                                    items={itemsEquipage}
                                    value={valueEquipage}
                                    label="Экипаж"
                                    onChange={({value}) => handleEquipageChange(value)}
                                    placeholder="Выберите экипаж"
                                />
                            </GridItem>
                            <GridItem>
                                <DatePicker label='Начало ремонта' value={startDate}
                                            onChange={({value}) => handleDateChange(value, 'from')}
                                            status={checkRepairDateFrom === true ? 'success' : 'alert'}
                                            caption={checkRepairDateFrom === false ? 'Наложение даты' : undefined}
                                            disabled={!valueEquipage}/>


                            </GridItem></> :
                        <GridItem col={'3'}>
                            <Text size='xl' font='mono'> Экипаж <b>{nodeData.data.equipage}</b>, в ремонте
                                с <b>{moment(nodeData.data.date_from).format('DD.MM.YYYY')}</b></Text>
                        </GridItem>
                    }
                    {/*FIO*/}
                    <GridItem col={"3"}>
                        <Grid cols={1}>
                            <GridItem>
                                <Text view='ghost'>ФИО ответственного</Text>
                            </GridItem>
                            <GridItem>
                                {!nodeData?.data.fio ?
                                    <TextField
                                        style={{width: "100%"}}
                                        onChange={handleChangeFIO}
                                        value={valueFIO}
                                        type="text"
                                        placeholder="Укажите ФИО"
                                    /> :
                                    <Text size='xl'><b>{nodeData?.data.fio}</b></Text>
                                }
                            </GridItem>
                        </Grid>
                    </GridItem>
                    {/*COMMENT*/}
                    <GridItem col={'3'}>
                        <TextField
                            onChange={handleChangeComment}
                            value={valueComment}
                            type="textarea"
                            cols={200}
                            rows={4}
                            placeholder="Примечание"
                        />

                    </GridItem>

                    <GridItem col={'3'}>
                        <Grid cols={3}>
                            <GridItem col={'3'}>
                                <Text view='ghost'>Завершение ремонта</Text>
                            </GridItem>
                            <GridItem col={'1'}>
                                {nodeData?.data?.date_to ?
                                    <Text font='mono'>{moment(nodeData.data.date_to).format('DD.MM.YYYY')}</Text> :
                                    <DatePicker value={endDate}
                                                onChange={({value}) => handleDateChange(value, 'to')}
                                                status={checkRepairDateTo === true ? 'success' : 'alert'}
                                                caption={checkRepairDateTo === false ? 'Наложение даты' : undefined}
                                                disabled={!valueEquipage}
                                                minDate={startDate ? startDate : undefined}/>
                                }
                            </GridItem>
                            <GridItem col={'2'}>
                                <div style={{position: 'absolute', right: '20px'}}>
                                    <Button
                                        size="m"
                                        view="primary"
                                        label="Сохранить"
                                        width="default"
                                        onClick={handleSaveButton}
                                        disabled={isSaving} // Добавляем блокировку кнопки во время сохранения

                                    />
                                    <Button
                                        className='button__cancel'
                                        size="m"
                                        view="primary"
                                        label="Отменить"
                                        width="default"
                                        onClick={() => {
                                            onClose(false);
                                        }}
                                    />
                                </div>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </Modal>
            {isAlert === 'success' && <SnackCmp alertType={isAlert} alertText={alertText}/>}
        </>
    )
};

export default ModalEquipageNewRepair;
