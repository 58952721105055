import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import React, {useEffect, useState} from "react";
import "../css/pageEquipage.css";
import {DragNDropField} from '@consta/uikit/DragNDropField';
import {Text} from '@consta/uikit/Text';
import GridBsiBsiList from "./component/grid.bsi.bsiList";
import ModalBsiPayload, {readFile} from "./component/modal.bsi.payload";
import ModalBsiDiagram from "./component/modal.bsi.diagram";


const PageBsi = () => {
    const [files, setFiles] = React.useState<File[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDiagramOpen, setIsModalDiagramOpen] = useState(false);
    const [newRowData, setNewRowData] = useState<any>(null);
    const [bsiFileDiagram, setBsiFileDiagram] = useState<string | null>();

    useEffect(() => {
        document.title = 'АСПО.Контроль | БСИ';
    }, []);

    const handleModalClose = (value: boolean) => {
        setIsModalOpen(false);
        setNewRowData(value)
        // setReturnedValue(value);
    };

    const handleDropFiles = (files: any) => {
        setFiles(files)
        setIsModalOpen(true)
    }

    const handleDropFilesDiagram = (files: any) => {
        readFile(files[0])
            .then((contents: any) => {
                setBsiFileDiagram(contents)
            })
        setIsModalDiagramOpen(true)
    }

    const handleModalDiagramClose = (value: boolean) => {
        setIsModalDiagramOpen(false);
        setBsiFileDiagram(null)
    };

    return (
        <>
            <Grid className="gridMain" cols="4" gap="xs">
                <GridItem col={2}>
                    <DragNDropField multiple={true}
                                    onDropFiles={(files) => handleDropFiles(files)}
                                    accept={['.txt']}>
                        {({openFileDialog}) => (
                            <>
                                <Text weight="black">
                                    Загрузка БСИ
                                </Text>
                                <Text>
                                    Перетащите сюда БСИ-файл экипажа или выберите его нажав на кнопку.
                                </Text>
                                <br/>
                                <Button size='s' onClick={openFileDialog} label="Выбрать файл"/>
                            </>
                        )}
                    </DragNDropField>
                </GridItem>
                <GridItem col={2}>
                    <DragNDropField multiple={false}
                                    onDropFiles={(files) => handleDropFilesDiagram(files)}
                                    accept={['.txt']}>
                        {({openFileDialog}) => (
                            <>
                                <Text weight="black">
                                    Просмотр диаграммы
                                </Text>
                                <Text>
                                    Перетащите сюда БСИ-файл экипажа или выберите его нажав на кнопку.
                                </Text>
                                <br/>
                                <Button size='s' onClick={openFileDialog} label="Выбрать файл"/>
                            </>
                        )}
                    </DragNDropField>
                </GridItem>
                <GridItem col={4}>
                    <GridBsiBsiList newRowData={newRowData}/>
                </GridItem>
            </Grid>
            <ModalBsiPayload isOpen={isModalOpen}
                             onClose={handleModalClose}
                             files={files}
                             nodeData={null}/>
            {bsiFileDiagram && <ModalBsiDiagram isOpen={isModalDiagramOpen}
                                                onClose={handleModalDiagramClose}
                                                bsiFileDiagram={bsiFileDiagram}
            />}
        </>
    )
}

export default PageBsi;