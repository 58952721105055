import React, {
    useCallback, useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import '../../../component/css/agGridGraph.css';
import axios from "axios";
import {
    API_GRAPH_LIST,
    API_GRAPH_UPDATE,
    API_SETTINGS_BLOCKDATE
} from "../../../service/api.endpoint";
import {Loader} from '@consta/uikit/LoaderCanary';
import {CellDoubleClickedEvent} from "@ag-grid-community/core";
import ModalGraphNewRecord, {isBlock} from "./modal.graph.newRecord";
import {SnackCmp} from "../../../component/snack.cmp";
import {columnDefs} from "./columns.grid.graph";
import {LicenseManager} from "@ag-grid-enterprise/core";
import {company} from "../../../component/header.cmp";
import {ModuleRegistry} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import TooltipGraph from "./tooltip.graph";
import cookieCmp from "../../../component/cookie.cmp";
import {useQuery} from "@tanstack/react-query";
import {v4 as uuidv4} from 'uuid';
import GetTaskResult from "../../../component/result.dramatiq.cmp";

ModuleRegistry.registerModules([SetFilterModule]);

const license = process.env.REACT_APP_AGGRID
if (license) {
    LicenseManager.setLicenseKey(license);
}

interface AgGridGraphProps {
    date: Date | null | 'loading';
    rowDataTransfer: any;
    setRowDataTransfer: any;
    loading: any
}

// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.put['X-CSRFToken'] = csrftoken;

const GridGraphGraphList: React.FC<AgGridGraphProps> = ({date, setRowDataTransfer, loading}) => {
        const [isModalOpen, setIsModalOpen] = React.useState(false);
        const gridRef = useRef<any>();
        const containerStyle = useMemo(() => ({width: '100%', height: '100%'}), []);
        const gridStyle = useMemo(() => ({height: '85vh', width: '100%'}), []);
        const [rowData, setRowData] = useState<any>();
        const [isLoading, setIsLoading] = useState(false);
        const [isDate, setIsDate] = useState<any>(null);
        const [graphParam, setGraphParam] = useState<any>()
        const [graphNode, setGraphNode] = useState<any>()
        const [dblClickParams, setDblClickParams] = useState<any>()
        const [isAlert, setIsAlert] = useState<'success' | 'alert' | undefined>(undefined)
        const [alertText, setAlertText] = useState<string>('')

        // blockDate
        const {isPending, error, data, refetch} = useQuery({
            queryKey: ['blockDate'],
            queryFn: () =>
                axios.get(API_SETTINGS_BLOCKDATE).then(
                    (res) => {
                        return res.data['block_date']
                    },
                ),
        })


        const sideBar = useMemo(() => {
            return {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'ВИД',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true,
                        },
                    },
                ],
            };
        }, []);

        // контекстное меню
        const getContextMenuItems = useCallback((params: any) => {
            let result = [];

            // форматируем клик в дату и проверяем на блокировку период для возможности удалить запись
            let selectedDate = params.node.data['workDate'].split('-')
            selectedDate[2] = params.column.colId.split('.')[0].slice(1)
            selectedDate = `${selectedDate[0]}-${selectedDate[1]}-${selectedDate[2]}`
            const resultIsBloc = isBlock(new Date(selectedDate).toLocaleDateString("ru-RU", {timeZone: "Asia/Yekaterinburg"}), parseInt(data))

            if (params.value === null && localStorage.getItem('username') === 'admin') {
                result.push(
                    {
                        name: 'Внеплановое мероприятие',
                        action: async () => {
                            const addRegistry = async () => {
                                const old_val = params.node.data[params.column.colId.split('.')[0]]
                                old_val['plan'] = 0
                                const newGraphData = {
                                    [params.column.colId.split('.')[0]]: old_val
                                };
                                try {
                                    await axios.put(`${API_GRAPH_UPDATE}/${params.node.data.pk}`, {
                                        data: newGraphData,
                                        action: 'upd',
                                        log_data: {
                                            date: selectedDate,
                                            deposit: params.node.data['deposit'],
                                            bush: params.node.data['bush'],
                                            wells: params.node.data['wells']['name'],
                                            username: localStorage.getItem('username'),
                                            action: 'GRAPH_INSERT',
                                            new_data: newGraphData
                                        }
                                    }, {
                                        headers: {
                                            'X-CSRFToken': csrftoken
                                        }
                                    });
                                } catch (error: any) {
                                    console.error('Ошибка при обновлении записи:', error.response.data);
                                }
                            };
                            await addRegistry();
                            const row_node = params.node
                            const field: string | undefined = params.column.colId
                            row_node.setDataValue(field, 0)
                            params.api.refreshCells({
                                rowNodes: [row_node],
                                force: true,
                            });
                        },
                        icon: '<img src="/pic/graph_ico/0.png" alt="Plan"/>'
                    },
                    // 'copy',
                    // 'separator',
                    // 'chartRange',
                )
            } else if (params.value !== 1 && resultIsBloc) {
                result.push(
                    {
                        name: 'Удалить мероприятие',
                        action: async () => {
                            const addRegistry = async () => {
                                const old_val = params.node.data[params.column.colId.split('.')[0]]
                                const plainD = {
                                    bsi: [
                                        {
                                            id: null,
                                            bsi: null,
                                            ppk: null,
                                            depth: null,
                                            label: null,
                                            lift_end: null,
                                            bsi_level: null,
                                            lift_start: null,
                                            lift_total: null,
                                            descent_end: null,
                                            descent_start: null,
                                            descent_total: null
                                        }
                                    ],
                                    fio: null,
                                    diam: null,
                                    fact: null,
                                    plan: params.value === 2 ? 1 : null,
                                    depth: null,
                                    level: null,
                                    comment: null,
                                    equipage: null,
                                    interval: null,
                                    time_end: null,
                                    time_start: null
                                }
                                const newGraphData = {
                                    [params.column.colId.split('.')[0]]: plainD
                                };
                                try {
                                    const queryResult = await axios.put(`${API_GRAPH_UPDATE}/${params.node.data.pk}`, {
                                        data: newGraphData,
                                        old_plan: old_val,
                                        action: 'del',
                                        username: localStorage.getItem('username'),
                                        log_data: {
                                            date: selectedDate,
                                            deposit: params.node.data['deposit'],
                                            bush: params.node.data['bush'],
                                            wells: params.node.data['wells']['name'],
                                            username: localStorage.getItem('username'),
                                            action: 'GRAPH_DELETE',
                                            old_data: old_val
                                        }
                                    }, {
                                        headers: {
                                            'X-CSRFToken': csrftoken
                                        }
                                    })
                                    return queryResult.data;
                                } catch (error: any) {
                                    console.error('Ошибка при обновлении записи:', error.response.data);
                                }
                            };

                            const queryResult = await addRegistry();
                            const row_node = params.node
                            console.log(queryResult)
                            const field: string | undefined = params.column.colId
                            row_node.setDataValue(field, null)
                            row_node.setDataValue('total_fact', queryResult.total_fact)
                            if (queryResult.action === 'action') {
                                row_node.setDataValue('level', queryResult.level)
                                row_node.setDataValue('bsi_level', queryResult.bsi_level)
                                row_node.setDataValue('comment', queryResult.comment)
                            }
                            params.api.refreshCells({
                                rowNodes: [row_node],
                                force: true,
                            });
                        },
                        icon: '<img src="/pic/cut.svg" alt="Cut"/>'
                    },
                    // 'copy',
                    // 'separator',
                    // 'chartRange',
                )
            }
            return result;
        }, []);

        // двойной клик на грид
        const onCellDoubleClicked = (params: CellDoubleClickedEvent) => {
            if (params.colDef.headerName && params.colDef.headerName.length <= 2) {
                if (date && params.colDef.field) {
                    // todo надо тоже переделать как в удалении контекст
                    const newDT = new Date(date)
                    newDT.setDate(parseInt(params.colDef.field.slice(1).split('.')[0]))
                    setGraphParam({
                        date: newDT.toLocaleDateString("ru-RU", {timeZone: "Asia/Yekaterinburg"}),
                        well: {
                            id: params.node.data.wells.id,
                            label: params.node.data.wells.name,
                            ppk: params.node.data.wells.ppk
                        },
                        collName: params.colDef.field.split('.')[0]
                    })
                    setGraphNode(params.node)
                }
                setDblClickParams(params)
                newRecordModal()
            }
        }

        // действие при закрытии модального окна
        const handleModalClose = (value: any, status: 'success' | 'alert' | undefined) => {
            setIsModalOpen(false);
            setGraphNode(null)
            setGraphParam(null)
            if (status) {
                if (status === 'alert') {
                    setAlertText('Произошла ошибка!')
                } else {
                    setAlertText('Действие выполнено успешно!')
                }
                setIsAlert(status)
            }
            console.log(value)
            if (value) {
                const row_node = dblClickParams.node
                const field: string | undefined = dblClickParams.colDef.field.split('.')[0]
                if (field && value?.code !== 'Escape') {
                    row_node.setDataValue(dblClickParams.colDef.field, value[field]['plan'])
                    row_node.setDataValue('total_fact', value['total_fact'])
                    value['bsi_level'] !== 'void' && row_node.setDataValue('bsi_level', value['bsi_level'])
                    value['level'] !== 'void' && row_node.setDataValue('level', value['level'])
                    value['diam'] !== 'void' && row_node.setDataValue('diam', value['diam'])
                    value['comment'] !== 'void' && row_node.setDataValue('comment', value['comment'])
                    row_node.data[field] = value[field]
                    // row_node.setDataValue(field, value[field]['bsi'])
                    dblClickParams.api.refreshCells({
                        rowNodes: [row_node],
                        force: true,
                    });
                }
                // setReturnedValue(value);
            }
        };

        // открываем модальное окно для добавления записи о проходке
        const newRecordModal = () => {
            setIsModalOpen(true)
        }

        // id строк в гриде
        const getRowId = useMemo(() => {
            return (params: any) => {
                return params.data.wells.id
            };
        }, []);

        const defaultColDef = useMemo(() => {
            return {
                // width: 120,
                sortable: true,
                resizable: true,
                tooltipComponent: TooltipGraph,
                enablePivot: true,
                // flex: 1
            };
        }, []);

        const autoGroupColumnDef = useMemo(() => {
            return {
                width: 200,
                resizable: true
            };
        }, []);


        // получение данных для грида графика
        const fetchData = async (formattedDate: any) => {
            try {
                // console.time("fetchData"); // Засекаем время выполнения

                // Генерируем UUID для таска драматик
                const requestId = uuidv4();

                // посылаем таск в драматик
                const response = await axios.get(API_GRAPH_LIST, {
                    params: {
                        date: formattedDate,
                        company: company,
                        requestId: requestId
                    }
                });
                // console.timeEnd("fetchData"); // Выводим время выполнения в консоль
                if (response.data['status'] === 'OK') {
                    let dramatiqResult = false

                    // получение результата таск
                    const fetchResult = async () => {
                        let resultTask = await GetTaskResult(requestId);
                        setIsLoading(false);
                        if (!resultTask.data) {
                            setTimeout(fetchResult, 2000);
                            return;
                        } else {
                            setRowDataTransfer(resultTask.data)
                            setRowData(resultTask.data);
                            loading(false)
                            dramatiqResult = true
                        }
                    }
                    // циклим пока не придет выполненная таска от драматик
                    if (!dramatiqResult) {
                        await fetchResult();
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };


        const statusBar = useMemo(() => {
            return {
                statusPanels: [{statusPanel: 'agAggregationComponent', align: 'right'}],
            };
        }, []);

        // флаг для первого рендера
        const firstUpdate = useRef(true);

        // действия при выборе месяца
        useEffect(() => {
            // если рендер первый то выходим
            // if (firstUpdate.current) {
            //     firstUpdate.current = false;
            //     return;
            // }
            const fetchDataAndReset = async () => {
                if (date && date !== 'loading' && date !== isDate) {
                    // инициализация state
                    setIsLoading(true);
                    loading(true)
                    setRowDataTransfer(null);
                    setRowData(null);

                    // вызов async функций
                    const formattedDate = date.toLocaleString("ru-RU", {timeZone: "Asia/Yekaterinburg"});
                    await fetchData(formattedDate);

                    // обновление данных после завершения fetchData
                    setIsLoading(false);
                    refetch();
                }
            };

            if (date && date !== 'loading') {
                fetchDataAndReset();
            }
        }, [date]);


        return (

            <div style={containerStyle}>
                {isLoading ? <Loader/> :
                    <div className="example-wrapper">
                        <div style={{marginBottom: '5px'}}>
                            <span id="eMessage"></span>
                        </div>
                        {graphNode && <ModalGraphNewRecord isOpen={isModalOpen}
                                                           onClose={handleModalClose}
                                                           props={graphParam}
                                                           nodeData={graphNode}/>}
                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                suppressAggFuncInHeader={true}
                                animateRows={true}
                                rowGroupPanelShow={'always'}
                                pivotPanelShow={'always'}
                                getRowId={getRowId}
                                defaultColDef={defaultColDef}
                                autoGroupColumnDef={autoGroupColumnDef}
                                sideBar={sideBar}
                                statusBar={statusBar}
                                // onGridReady={onGridReady}
                                columnHoverHighlight={true}
                                onCellDoubleClicked={onCellDoubleClicked}
                                enableRangeSelection={true}
                                allowContextMenuWithControlKey={true}
                                getContextMenuItems={getContextMenuItems}
                                tooltipShowDelay={0}
                                tooltipHideDelay={6000}
                            />

                        </div>
                        {isAlert && <SnackCmp alertType={isAlert} alertText={alertText}/>}
                    </div>
                }
            </div>
        );
    }
;

export default GridGraphGraphList;