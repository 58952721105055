import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {PageLogin} from "../page/login/page.login";
import PageMain from "../page/page.main";
import {API_CHECK_AUTH_URL, API_TOKEN_REFRESH} from "../service/api.endpoint";

const WrapperAuthCmp = ({children}: { children: React.ReactNode }) => {
    const [isAuth, setIsAuth] = useState(false)
    const [loading, setLoading] = useState(true);
    const refreshAccessToken = async () => {
        try {
            const refresh = localStorage.getItem('refreshToken');
            const response = await axios.post(API_TOKEN_REFRESH, {
                refresh,
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            });
            if (response.status === 200) {
                // Обновление токенов в хранилище состояния
                const {access, refresh} = response.data;
                const responseUserInfo = await axios.get(API_CHECK_AUTH_URL, {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        'Authorization': `Bearer ${access}`,
                    },
                });
                if (responseUserInfo.status === 200) {
                    localStorage.setItem('company', responseUserInfo.data.data.last_name);
                    localStorage.setItem('username', responseUserInfo.data.data.username);
                    localStorage.setItem('accessToken', access);
                    localStorage.setItem('refreshToken', refresh);
                    setIsAuth(true)
                }
            } else {
                throw new Error(`Something went wrong: code ${response.status}`);
            }
        } catch (error) {
            // Обработка ошибок при обновлении токена
            console.log(error);
        }
    };


    useEffect(() => {
        const access = localStorage.getItem('accessToken');
        const checkAuth = async () => {
            try {
                if (access) {
                    const response = await axios.get(API_CHECK_AUTH_URL, {
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                            'Authorization': `Bearer ${access}`,
                        },
                    });

                    if (response.status === 200) {
                        const responseUserInfo = await axios.get(API_CHECK_AUTH_URL, {
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8',
                                'Authorization': `Bearer ${access}`,
                            },
                        });
                        if (responseUserInfo.status === 200) {
                            localStorage.setItem('company', responseUserInfo.data.data.last_name);
                            localStorage.setItem('username', responseUserInfo.data.data.username);
                            setIsAuth(true);
                        }

                    } else if (response.status === 401) {
                        throw new Error('refresh');
                    } else {
                        throw new Error(`Something went wrong: code ${response.status}`);
                    }
                }
            } catch (error: any) {
                if (error.message === "Request failed with status code 401") {
                    // Обновление токена
                    console.log('refresh tok')
                    await refreshAccessToken();
                } else {
                    // Обработка других ошибок
                    console.log(error);
                }
            } finally {
                setLoading(false);
            }
        };

        !isAuth && checkAuth();
    }, []);

    if (loading) {
        return (<>Loading</>)
    }

    return (
        <>
            {isAuth ? <PageMain loading={loading}/> : <PageLogin/>}
        </>

    );
};

export default WrapperAuthCmp;