// @ts-ignore
import ExcelJs from "exceljs";
import 'moment/locale/ru'; // импортируем русскую локаль
import {API_REPORTS_REPORT1} from "../../../service/api.endpoint";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import GetTaskResult from "../../../component/result.dramatiq.cmp";


const ReportToExcel = async (date: string, setLoading: any, data?: any) => {

    function createReport1(repData: any) {
        const workbook = new ExcelJs.Workbook();

        // создаем листы в книге
        const worksheet = workbook.addWorksheet("ДАТА", {
            views: [{showGridLines: true}]
        });

        worksheet.getCell("A1").value = date;
        // делаем шапку //

        // Объединяем ячейки
        worksheet.mergeCells("B2:B4");
        worksheet.mergeCells("C2:C4");
        worksheet.mergeCells("D2:D4");
        worksheet.mergeCells("E2:E4");
        worksheet.mergeCells("F2:G3");
        worksheet.mergeCells("H2:H4");
        worksheet.mergeCells("I2:L2");
        worksheet.mergeCells("J3:J3");
        worksheet.mergeCells("M2:M3");
        worksheet.mergeCells("N2:N3");
        worksheet.mergeCells("O2:O3");
        worksheet.mergeCells("P2:R3");
        worksheet.mergeCells("S2:S4");
        worksheet.mergeCells("T2:U3");

        // имена в шапке и ширина столбцов
        worksheet.getCell("B2").value = "Подрядная организация";
        worksheet.getColumn(2).width = 25
        worksheet.getCell("C2").value = "Общее количество";
        worksheet.getColumn(3).width = 12
        worksheet.getCell("D2").value = "ЦОД Север";
        worksheet.getColumn(4).width = 12
        worksheet.getCell("E2").value = "ЦОД Восток";
        worksheet.getColumn(5).width = 12
        worksheet.getCell("F2").value = "Кол-во экипажей";
        worksheet.getCell("F4").value = "план";
        worksheet.getColumn(6).width = 8
        worksheet.getColumn(7).width = 8
        worksheet.getCell("G4").value = "факт";
        worksheet.getCell("H4").value = "комментарий";
        worksheet.getColumn(8).width = 33
        worksheet.getCell("I2").value = "Фонд";
        worksheet.getCell("I3").value = "график";
        worksheet.getCell("J3").value = "охваченный";
        worksheet.getCell("L3").value = "не охваченный";
        worksheet.getCell("I4").value = "СКВ.";
        worksheet.getCell("J4").value = "СКВ.";
        worksheet.getCell("K4").value = "скв. более 1 обработки";
        worksheet.getCell("L4").value = "СКВ";
        worksheet.getColumn(9).width = 12
        worksheet.getColumn(10).width = 12
        worksheet.getColumn(11).width = 12
        worksheet.getColumn(12).width = 12
        worksheet.getCell("M2").value = "Не охвачено по орг. Причинам";
        worksheet.getCell("M4").value = "СКВ.";
        worksheet.getColumn(13).width = 12
        worksheet.getCell("N2").value = "Кол-во не проходов";
        worksheet.getCell("N4").value = "СКВ.";
        worksheet.getColumn(14).width = 9
        worksheet.getCell("O2").value = "Кол-во ликвид (в.тек.мес)";
        worksheet.getCell("O4").value = "СКВ.";
        worksheet.getColumn(15).width = 10
        worksheet.getCell("P2").value = "Кол-во обработок в тек.месяце";
        worksheet.getCell("P4").value = "план";
        worksheet.getCell("Q4").value = "факт";
        worksheet.getCell("R4").value = "отстав.";
        worksheet.getColumn(16).width = 7
        worksheet.getColumn(17).width = 7
        worksheet.getColumn(18).width = 7
        worksheet.getCell("S2").value = "Примечание";
        worksheet.getColumn(19).width = 40
        worksheet.getCell("T2").value = "Работы по восстановлению прохода по НКТ";
        worksheet.getCell("T4").value = "Ликвидировано";
        worksheet.getCell("U4").value = "Не ликвидировано";
        worksheet.getColumn(20).width = 35
        worksheet.getColumn(21).width = 21

        // Задаем стиль для ячеек шапки
        const style = {
            border: {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            }
        };

        // перебераем шапку таблицы
        for (let i = 2; i <= 4; i++) {
            const row = worksheet.getRow(i);
            for (let i = 2; i <= 21; i++) {
                const cell = row.getCell(i);
                // @ts-ignore
                cell.border = style.border;
                cell.font = {bold: true}
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: '66ccff'},
                };
                // cell.alignment = {
                //     horizontal: 'center', vertical: 'middle', wrapText: true,
                // };
            }
        }

        // выравниваем по центру и по середине, перенос строк
        for (let col = 2; col <= 22; col++) {
            const column = worksheet.getColumn(col);
            column.alignment = {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            };
        }

        // инсертим данные
        let rowNum = 0
        repData.data.map((e: any, index: number) => {
            rowNum = index + 5
            const row = worksheet.getRow(rowNum)
            row.height = 50
            row.values = ['', e['company'], e['total_plan'], e['total_north'], e['total_east'], e['total_equipage'], e['equipage_fact'],
                '', e['graph'], e['day_plan_work'], e['total_plan_work_more'], e['differ_plan_work'], e['by_org_reason'], e['no_passage'], e['liquidation'], e['day_plan_total'],
                e['day_fact_total'], e['day_differ_total']];
            // Добавляем границы вокруг ячеек
            for (let i = 2; i <= 21; i++) {
                const cell = row.getCell(i)
                // @ts-ignore
                cell.border = style.border;
            }
        })

        // непроходы
        rowNum += 2
        let noPassageHeader: boolean = false
        repData.data.map((e: any, index: any) => {
            if (e['no_passage'] > 0) {
                if (!noPassageHeader) {
                    rowNum += 1
                    worksheet.mergeCells(`B${rowNum}:H${rowNum}`);
                    const headerCell = worksheet.getCell(`B${rowNum}`)
                    headerCell.value = 'Непроходы:'
                    headerCell.alignment = {
                        vertical: 'middle',
                        horizontal: 'left',
                        wrapText: false
                    };
                    headerCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: '66ccff'},
                    };
                    // @ts-ignore
                    headerCell.border = style.border;
                    headerCell.font = {bold: true};
                    noPassageHeader = true
                }
                e['no_passage_data'].map((el: any) => {
                    let isLiquidation: boolean = false;
                    e['liquidation_data'].map((lItem: any) => {
                        if (lItem.bush === el.bush && lItem.wells == el.wells) {
                            isLiquidation = true
                        }
                    });


                    rowNum += 1
                    const row = worksheet.getRow(rowNum)
                    row.values = ['', e['company'], el['day'], el['location'], el['deposit'], el['bush'], el['wells'], el['depth'], el['input_type']]
                    // Добавляем границы вокруг ячеек
                    const cellCount = row.cellCount
                    for (let i = 2; i <= cellCount; i++) {
                        const cell = row.getCell(i)
                        if (isLiquidation) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: {argb: '91cb8b'},
                            };
                        }


                        // @ts-ignore
                        cell.border = style.border;
                    }
                })
            }
        })

        // ликвидации
        rowNum += 2
        let liquidationHeader: boolean = false
        repData.data.map((e: any, index: any) => {
            if (e['liquidation'] > 0) {
                if (!liquidationHeader) {
                    rowNum += 1
                    worksheet.mergeCells(`B${rowNum}:H${rowNum}`);
                    const headerCell = worksheet.getCell(`B${rowNum}`)
                    headerCell.value = 'ЛГПП:'
                    headerCell.alignment = {
                        vertical: 'middle',
                        horizontal: 'left',
                        wrapText: false
                    };
                    headerCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: '66ccff'},
                    };
                    // @ts-ignore
                    headerCell.border = style.border;
                    headerCell.font = {bold: true};
                    liquidationHeader = true
                }
                e['liquidation_data'].map((el: any) => {
                    rowNum += 1
                    const row = worksheet.getRow(rowNum)
                    row.values = ['', e['company'], el['day'], el['location'], el['deposit'], el['bush'], el['wells'], el['depth'], el['input_type']]
                    // Добавляем границы вокруг ячеек
                    const cellCount = row.cellCount
                    for (let i = 2; i <= cellCount; i++) {
                        const cell = row.getCell(i)
                        // @ts-ignore
                        cell.border = style.border;
                    }
                })
            }
        })

        // по орг причинам
        let byOrgReasonHeader: boolean = false
        rowNum += 2
        repData.data.map((e: any, index: any) => {
            if (e['by_org_reason'] > 0) {
                if (!byOrgReasonHeader) {
                    rowNum += 1
                    worksheet.mergeCells(`B${rowNum}:H${rowNum}`);
                    const headerCell = worksheet.getCell(`B${rowNum}`)
                    headerCell.value = 'Непроходы по орг причинам:'
                    headerCell.alignment = {
                        vertical: 'middle',
                        horizontal: 'left',
                        wrapText: false
                    };
                    headerCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: '66ccff'},
                    };
                    headerCell.font = {bold: true};
                    // @ts-ignore
                    headerCell.border = style.border;
                    byOrgReasonHeader = true
                }
                e['by_org_reason_data'].map((el: any) => {
                    rowNum += 1
                    const row = worksheet.getRow(rowNum)
                    row.values = ['', e['company'], el['day'], el['location'], el['deposit'], el['bush'], el['wells'], el['comment']['label']]

                    // Добавляем границы вокруг ячеек
                    const cellCount = row.cellCount
                    for (let i = 2; i <= cellCount; i++) {
                        const cell = row.getCell(i)
                        // @ts-ignore
                        cell.border = style.border;
                    }
                })

            }

        })
        // console.timeEnd("fetchData"); // Выводим время выполнения в консоль
        setLoading(false)

        const writeFile = (fileName: any, content: any) => {
            const link = document.createElement("a");
            const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });

            // Добавляем эту строку для поддержки заметок
            const url = URL.createObjectURL(blob);

            link.download = fileName;
            link.href = url;

            link.click();

            // Очищаем URL после скачивания файла
            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 100);
        };
        workbook.xlsx.writeBuffer().then(buffer => {
            writeFile(`report_${date.replace('.', '')}.xlsx`, buffer);
        });
    }

    // console.time("fetchData"); // Засекаем время выполнения


    if (data) {
        createReport1(data);
    } else {
        let repData

        // Генерируем UUID для таска драматик
        const requestId = uuidv4();

        const response = await axios.get(API_REPORTS_REPORT1, {params: {date: date, requestId: requestId}})

        if (response.data['status'] === 'OK') {
            let dramatiqResult = false

            // получение результата таск
            const fetchResult = async () => {
                let resultTask = await GetTaskResult(requestId);
                if (!resultTask.data) {
                    setTimeout(fetchResult, 2000);
                    return;
                } else {
                    createReport1(resultTask)
                    dramatiqResult = true
                }
            }
            // циклим пока не придет выполненная таска от драматик
            if (!dramatiqResult) {
                await fetchResult();
            }
        }
    }
};

export default ReportToExcel;
