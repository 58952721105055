import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import '../../../component/css/agGridGraph.css';
import axios from "axios";
import {API_EQUIPAGE_REPAIR} from "../../../service/api.endpoint";
import {Loader} from '@consta/uikit/LoaderCanary';
import {company} from "../../../component/header.cmp";
import {CellDoubleClickedEvent} from "@ag-grid-community/core";
import ModalEquipageNewRepair from "./modal.equipage.newRepair";
import {LicenseManager} from "@ag-grid-enterprise/core";
import moment from "moment/moment";

const license = process.env.REACT_APP_AGGRID
if (license) {
    LicenseManager.setLicenseKey(license);
}

interface RepairListInt {
    isUpdate: boolean
}

const GridEquipageRepairList: React.FC<RepairListInt> = ({isUpdate}) => {
    const gridRef = useRef<any>();
    const containerStyle = useMemo(() => ({width: '100%', height: '100%'}), []);
    const gridStyle = useMemo(() => ({height: '85vh', minWidth: '400px'}), []);
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [dblClickParams, setDblClickParams] = useState<any>()
    const [graphParam, setGraphParam] = useState<any>()
    const [graphNode, setGraphNode] = useState<any>()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [returnedValue, setReturnedValue] = useState(false);

    const handleModalClose = (value: any) => {
        setIsModalOpen(false);
        setGraphParam(null)
        setGraphNode(null)

    };
    const onCellDoubleClicked = (params: CellDoubleClickedEvent) => {
        setDblClickParams(params)
        setIsModalOpen(true)
        setGraphParam({
            collName: params.colDef.field
        })
        setGraphNode(params.node)
    }

    // const defaultFilter = {
    //     filterType: 'date',
    //     comparator: '<',
    //     dateFrom: new Date().toISOString()
    // }

    const columnDefs = [
        {
            field: 'id',
            headerName: 'id',
            hide: true
        },
        {
            field: 'equipage',
            headerName: 'Гос. номер',
            initialWidth: 90,
            filter: true,
            enableRowGroup: true,
        },
        {
            field: 'org',
            headerName: 'ПО',
            initialWidth: 100,
            filter: true,
            enableRowGroup: true,
        },
        {
            field: 'created_at',
            headerName: 'Добавлен',
            initialWidth: 100,
            filter: true,
            valueFormatter: (params: any) => moment(params.value).add(5, 'hour').format('DD.MM.YYYY HH:mm')
        },
        {
            field: 'updated_at',
            headerName: 'Обновлен',
            initialWidth: 100,
            filter: true,
            valueFormatter: (params: any) => moment(params.value).add(5, 'hour').format('DD.MM.YYYY HH:mm')
        },
        {
            field: 'date_from',
            headerName: 'В ремонте с:',
            filter: true,
            valueFormatter: (params: any) => moment(params.value).format('DD.MM.YYYY')
        },
        {
            field: 'date_to',
            headerName: 'В ремонте по:',
            filter: true,
            valueFormatter: (params: any) => params.value ? moment(params.value).format('DD.MM.YYYY') : 'Не указано'
        },
        {
            field: 'comment',
            headerName: 'Примечание',
        },
        {
            field: 'fio',
            filter: true,
            headerName: 'ФИО ответственного',
            enableRowGroup: true,
        },

    ];

    const getRowId = useMemo(() => {
        return (params: any) => {
            return params.data.id
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            // width: 120,
            sortable: true,
            resizable: true,
            flex: 1
        };
    }, []);


    const fetchData = async () => {
        try {
            // console.time("fetchData"); // Засекаем время выполнения
            const response = await axios.get(API_EQUIPAGE_REPAIR, {
                params: {
                    name: localStorage.getItem('username'),
                    company: localStorage.getItem('company')
                }
            });
            // console.timeEnd("fetchData"); // Выводим время выполнения в консоль
            setIsLoading(false);
            return response.data

            // return response.data
        } catch (error) {
            console.log(error);
        }
    };

    const onGridReady = async (params: any) => {
        // const data = await fetchData()
        setRowData(await fetchData())
    };

    useEffect(() => {
        setIsLoading(true)
        onGridReady(null); // Вызываем функцию при монтировании компонента
    }, []);

    useEffect(() => {
        setIsLoading(true)
        onGridReady(null); // Вызываем функцию при монтировании компонента
    }, [isUpdate]);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
            ],
        };
    }, []);

    return (
        <div style={containerStyle}>
            <div className="example-wrapper">
                <div style={{marginBottom: '5px'}}>
                    <span id="eMessage"></span>
                </div>

                <div style={gridStyle} className="ag-theme-alpine">
                    {isLoading ? <Loader/> :
                        // 'ПЕТУШНЯ ДЕШЕВАЯ'
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            animateRows={true}
                            getRowId={getRowId}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            statusBar={statusBar}
                            onCellDoubleClicked={onCellDoubleClicked}
                            rowGroupPanelShow={'always'}
                        />
                    }
                </div>
                <ModalEquipageNewRepair isOpen={isModalOpen}
                                        onClose={handleModalClose}
                                        props={graphParam}
                                        nodeData={graphNode}/>
            </div>
        </div>
    );
};

export default GridEquipageRepairList;