import React, {useMemo} from 'react';
import {level} from "../../../component/level.cmp";
import "../tooltip.css"
import {Grid, GridItem} from "@consta/uikit/Grid";
import ReactDOM from "react-dom/client";


const TooltipGraph = (props: any) => {

    const collName = props.colDef.field.split('.')[0]
    const data = useMemo(
        () => props.api.getDisplayedRowAtIndex(props.rowIndex).data[collName],
        []
    );
    const bsiData = props.data[collName].bsi
    return (
        <div className="custom-tooltip">
            {(!bsiData || bsiData[bsiData.length - 1].id === null) ?
                // <Grid cols={'1'}>
                //     <GridItem col={"1"}>
                //         <p>
                //             <span><b>{data.level.label}</b></span>
                //         </p>
                //         <p>
                //             <span>Глубина: </span> {data.depth}
                //         </p>
                //     </GridItem>
                // </Grid>
                (data.level?.label ?
                        <>
                            <p>
                                <span><b>РУЧНОЙ ВВОД:</b></span>
                            </p>
                            <p>
                                <span><u>{data.level?.label}</u></span>
                            </p>
                            <p>
                                <span>Глубина: </span> {data.depth}
                            </p>
                            <p>
                                <span><b>{data.comment && data.comment.label}</b></span>
                            </p>


                        </> :
                        <p>
                            <span><b>{data.comment ? data.comment.label : 'ДАННЫЕ ОТСУТСТВУЮТ!'}</b></span>
                        </p>
                ) :
                <>
                    <Grid cols={2} gap="xs">
                        <GridItem col={1}><b>БСИ:</b></GridItem>
                        <GridItem col={1}><b>РУЧНОЙ ВВОД:</b></GridItem>
                        {bsiData && bsiData.map((el: any, index: number) =>
                            <React.Fragment key={index}>

                                <GridItem col={1}>
                                    <p>
                                        {/*todo*/}
                                        <span><u>{el.bsi_level?.name}</u></span>
                                    </p>
                                    <p>
                                        <span>Глубина: </span> {el.depth.toFixed(1)}
                                    </p>
                                    <p>
                                        <span>Время спуска: </span> {el.descent_total}
                                    </p>
                                    <p>
                                        <span>Время подьема: </span> {el.lift_total}
                                    </p>
                                </GridItem>
                                < GridItem col={1}>
                                    {index === bsiData.length - 1 &&
                                        <>
                                            <p>
                                                <span><u>{data.level?.label}</u></span>
                                            </p>
                                            <p>
                                                <span>Глубина: </span> {data.depth}
                                            </p>
                                        </>
                                    }
                                </GridItem>
                            </React.Fragment>
                        )}

                    </Grid>
                </>
            }
        </div>
    );
};
export default TooltipGraph;