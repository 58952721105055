import {Modal} from '@consta/uikit/Modal';
import {Text} from '@consta/uikit/Text';
import {SnackCmp} from "../../../component/snack.cmp";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {DatePicker} from "@consta/uikit/DatePicker";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Button} from "@consta/uikit/Button";
import React, {useEffect, useState} from "react";
// @ts-ignore
import _ from "lodash";
import axios from "axios";
import {
    API_CLIFF,
    API_EQUIPAGE_REPAIR,
    API_EQUIPAGE_REPAIR_UPDATE, API_REPAIR_CHECK_DATE,
    API_URL_DICT, API_URL_WELL
} from "../../../service/api.endpoint";
import {company} from "../../../component/header.cmp";
import moment from "moment/moment";
import cookieCmp from '../../../component/cookie.cmp';
import {IconSettings} from "@consta/uikit/IconSettings";
import {Slider} from "@consta/uikit/Slider";

export type Item = {
    label: string;
    id: string;
    pk?: string;
};

type DictType = {
    pk: string,
    name: string,
    comment: { name: string, pk: string }[],
    level: { name: string, pk: string }[],
    equipage: { name: string, pk: string }[],
}

interface ModalComponentProps {
    isOpen: boolean;
    onClose: any;
    nodeData: any
    props: any
}

// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

const ModalCliffView: React.FC<ModalComponentProps> = ({props, isOpen, onClose, nodeData}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [valueWells, setValueWells] = useState<Item | null>();
    const [valueComment, setValueComment] = useState<string | null>();
    const [valueFIO, setValueFIO] = useState<string | null>(null);
    const [itemsWells, setItemsWells] = useState<Item[]>([]);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [isAlert, setIsAlert] = useState<string | null>(null)
    const [alertText, setAlertText] = useState<string>('')
    const [valueDepth, setValueDepth] = useState<number>(0);
    const [inputValue, setInputValue] = useState<string | undefined | null>();


    const handleSaveButton = async () => {
        setIsSaving(true); // Устанавливаем флаг сохранения в true
        try {
            await addCliff();
        } finally {
            setIsSaving(false);
        }
        clearData()
    };

    const handleCloseButton = () => {
        clearData()
        onClose(false);

    }

    function clearData() {
        setValueWells(null)
        setValueFIO(null)
        setValueComment(null)
        setStartDate(null)
        setEndDate(null)
        setValueDepth(0)
    }

    const handleChangeComment = ({value}: { value: string | null }) => setValueComment(value);

    const addCliff = async () => {
        const newData = {
            wells: valueWells?.pk,
            org_cliff: company,
            org_extract: endDate ? company : null,
            cliff_date: nodeData ? nodeData.data?.date_from : startDate && moment(startDate.toLocaleString("ru-RU", {timeZone: "Asia/Yekaterinburg"}),
                'DD.MM.YYYY, HH:mm:ss').format('YYYY-MM-DD'),
            extraction_date: nodeData && nodeData.data?.date_to ? nodeData.data.date_to : endDate && moment(endDate.toLocaleString("ru-RU", {timeZone: "Asia/Yekaterinburg"}),
                'DD.MM.YYYY, HH:mm:ss').format('YYYY-MM-DD'),
            comment: valueComment,
            depth: valueDepth,
            username: localStorage.getItem('username'),
            cliff_id: nodeData?.data?.wells && nodeData.data.pk
        };
        if (nodeData?.data?.wells) {
            try {
                const response = await axios.put(`${API_CLIFF}`, newData);
                // закрыть модальное окно
                setAlertText('Запись успешно обновлена!')
                setIsAlert('success');
                clearData()
                onClose(newData);
                console.log('Запись успешно обновлена:', response.data);
            } catch (error: any) {
                setAlertText('Ошибка при обновлении записи!')
                setIsAlert('alert');
                onClose(false);
                console.error('Ошибка при обновлении записи:', error.response.data);
            }
        } else {
            try {
                const response = await axios.post(`${API_CLIFF}`, newData, {
                    headers: {
                        'X-CSRFToken': csrftoken
                    }
                });
                // закрыть модальное окно
                setAlertText('Запись успешно добавлена!')
                setIsAlert('success');
                clearData()
                onClose(newData);
                console.log('Запись успешно добавлена:', response.data);
            } catch (error: any) {
                setAlertText('Ошибка при добавлении записи!')
                setIsAlert('alert');
                onClose(false);
                console.error('Ошибка при добавлении записи:', error.response.data);
            }
        }
    };


    const fetchWells = async (inputValue: any) => {
        try {
            const response = await axios.get(API_URL_WELL, {
                params: {search: inputValue} // или другой параметр, который подходит вашему API
            });
            setItemsWells(response.data); // Установка состояния с полученными данными
        } catch (error) {
            console.error('Error fetching well list:', error);
            // Обработка ошибки здесь
        }
    };

    // обновление списка скважин по вводу в поле
    useEffect(() => {
        if (inputValue && inputValue.length > 0) {
            fetchWells(inputValue);
        } else {
            setItemsWells([]);
        }
    }, [inputValue]);

    const handleWellChange = (value: any) => {
        setInputValue(value)
        setValueWells(value);
        setStartDate(null)
        setEndDate(null)
    };

    useEffect(() => {
        if (nodeData?.data?.comment) {
            setValueComment(nodeData.data.comment)
        }
    }, [nodeData, props?.collName]);


    useEffect(() => {
        if (nodeData?.data.wells) {
            if (nodeData?.data.extraction_date) {
                setIsSaving(true)
            } else {
                !endDate ? setIsSaving(true) : setIsSaving(false)
            }

        } else {
            if (!startDate || !valueWells || !valueDepth) {
                setIsSaving(true);
            } else {
                setIsSaving(false);
            }
        }
    }, [startDate, endDate, valueWells, valueFIO, valueComment]);

    const handleDateChange = async (date: any, action: string) => {
        if (action === 'from') {
            setStartDate(date)
        } else {
            setEndDate(date)
        }
    }

    return (
        <>
            <Modal
                className="modal"
                isOpen={isOpen}
                hasOverlay
                // onClickOutside={onClose}
                onEsc={onClose}
            >
                {isAlert === 'alert' && <SnackCmp alertType={isAlert} alertText={alertText}/>}
                <Grid cols="3" gap="xs">
                    {!nodeData?.data.wells ?
                        <>
                            <GridItem col={"2"}>
                                <Combobox
                                    items={itemsWells}
                                    value={valueWells}
                                    label="Скважина"
                                    onChange={({value}) => handleWellChange(value)}
                                    placeholder="Выберите скважину"
                                    onInputChange={({value}) => setInputValue(value)}
                                />
                            </GridItem>
                            <GridItem>
                                <DatePicker label='Дата обрыва' value={startDate}
                                            onChange={({value}) => handleDateChange(value, 'from')}
                                            disabled={!valueWells}/>
                            </GridItem>
                            <GridItem col={"3"}>
                                <GridItem col={"3"}>
                                    <Slider className="inputElement"
                                            value={valueDepth}
                                            label={`Глубина ${valueDepth}м`}
                                            withTooltip
                                            min={0}
                                            max={1000}
                                            step={1}
                                            view="default"
                                            leftSide="input"
                                            rightSide={IconSettings}
                                            onChange={({value}) => setValueDepth(value)}

                                    />
                                </GridItem>

                            </GridItem>
                        </> :
                        <GridItem col={'3'}>
                            <Text size='xl'
                                  font='mono'><b>{moment(nodeData.data.cliff_date).format('DD.MM.YYYY')}</b></Text>
                            <Text size='xl' font='mono'> Обрыв инструмента
                                скв.<b>{nodeData.data.wells.name} (кп.№{nodeData.data.wells.bush.name})</b> на
                                глубине <b>{nodeData.data.depth}м.</b></Text>
                        </GridItem>
                    }
                    {/*COMMENT*/}
                    <GridItem col={'3'}>
                        <TextField
                            onChange={handleChangeComment}
                            value={valueComment}
                            type="textarea"
                            cols={200}
                            rows={4}
                            placeholder="Примечание"
                            disabled={nodeData?.data.wells}
                        />
                    </GridItem>

                    <GridItem col={'3'}>
                        <Grid cols={3}>
                            <GridItem col={'3'}>
                                <Text view='ghost'>Дата извлечения</Text>
                            </GridItem>
                            <GridItem col={'1'}>
                                {nodeData?.data?.extraction_date ?
                                    <Text font='mono'>{moment(nodeData.data.date_to).format('DD.MM.YYYY')}</Text> :
                                    <DatePicker value={endDate}
                                                onChange={({value}) => handleDateChange(value, 'to')}
                                                minDate={moment(nodeData?.data && nodeData.data.cliff_date, 'YYYY-MM-DD').toDate()}/>
                                }
                            </GridItem>
                            <GridItem col={'2'}>
                                <div style={{position: 'absolute', right: '20px'}}>
                                    <Button
                                        size="m"
                                        view="primary"
                                        label="Сохранить"
                                        width="default"
                                        onClick={handleSaveButton}
                                        disabled={isSaving} // Добавляем блокировку кнопки во время сохранения

                                    />
                                    <Button
                                        className='button__cancel'
                                        size="m"
                                        view="primary"
                                        label="Отменить"
                                        width="default"
                                        onClick={handleCloseButton}
                                    />
                                </div>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </Modal>
            {isAlert === 'success' && <SnackCmp alertType={isAlert} alertText={alertText}/>}
        </>
    )
};

export default ModalCliffView;
