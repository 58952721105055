import React, {useEffect, useState} from 'react';
import HeaderCmp from "../component/header.cmp";
import "./css/mainPage.css";
import PageGraph from "./graph/page.graph";
import PageEquipage from "./equipage/page.equipage";
import PageBsi from "./bsi/page.bsi";
import {ModuleRegistry} from '@ag-grid-community/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {StatusBarModule} from '@ag-grid-enterprise/status-bar';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {RowGroupingModule} from '@ag-grid-enterprise/row-grouping';
import {RangeSelectionModule} from '@ag-grid-enterprise/range-selection';
// import {AgGridReact} from '@ag-grid-community/react';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {FiltersToolPanelModule} from '@ag-grid-enterprise/filter-tool-panel';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import PageReport from "./report/page.report";
import PageLogging from "./logging/page.logging";
import PageCliff from "./cliff/page.cliff";
import PagePPK from "./ppk/page.ppk";


ModuleRegistry.registerModules([ClientSideRowModelModule,
    RangeSelectionModule, RowGroupingModule, RichSelectModule, ColumnsToolPanelModule,
    FiltersToolPanelModule, StatusBarModule, MenuModule, ExcelExportModule]);


ModuleRegistry.registerModules([ExcelExportModule]);

const PageMain = (props: { loading: boolean }) => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);

    useEffect(() => {
        document.title = 'АСПО.Контроль | Главная';
    }, []);

    return (
        <>
            {!props.loading && <HeaderCmp setSelectedMenuItem={setSelectedMenuItem}/>}
            {selectedMenuItem === "БСИ" && <PageBsi/>}
            {selectedMenuItem === "График" && <PageGraph/>}
            {selectedMenuItem === "Экипаж" && <PageEquipage/>}
            {selectedMenuItem === "Обрывы" && <PageCliff/>}
            {selectedMenuItem === "ППК" && <PagePPK/>}
            {selectedMenuItem === "Отчеты" && <PageReport/>}
            {selectedMenuItem === "Логи" && <PageLogging/>}

        </>
    );
}

export default PageMain;