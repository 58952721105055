import React, {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import '../../../component/css/agGridGraph.css';
import axios from "axios";
import {API_LOGGING_LIST} from "../../../service/api.endpoint";
import {Loader} from '@consta/uikit/LoaderCanary';
import moment from "moment";
import {DatePicker} from "@consta/uikit/DatePicker";
import {v4 as uuidv4} from "uuid";
import GetTaskResult from "../../../component/result.dramatiq.cmp";

const GridLoggingLogList: React.FC = () => {
    const gridRef = useRef<any>();
    const containerStyle = useMemo(() => ({width: '100%', height: '100%'}), []);
    const gridStyle = useMemo(() => ({minHeight: '90vh', width: '100%'}), []);
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const today = moment().toDate()
    const lastWeek = moment().subtract(3, 'days').toDate();
    const [valueDateRange, setValueDateRange] = useState<[Date?, Date?] | null>([lastWeek, today]);
    const columnDefs = [
        {
            field: 'pk',
            headerName: '№',
            // hide: true
        },
        {
            field: 'created_at',
            headerName: 'Время события',
            filter: true,
        },
        {
            field: 'action',
            headerName: 'Действие',
            filter: true,
        },
        {
            field: 'user',
            headerName: 'Пользователь',
            filter: true,
        },
        {
            field: 'general_info.deposit',
            headerName: 'Месторождение',
            filter: true,
        },
        {
            field: 'general_info.bush',
            headerName: 'Куст',
            filter: true,
        },
        {
            field: 'general_info.wells',
            headerName: 'Скважина',
            filter: true,
        },
        {
            field: 'general_info.equipage',
            headerName: 'Экипаж',
            filter: true,
        },
        {
            field: 'general_info.date',
            headerName: 'Дата',
            filter: true,
            valueFormatter: (params: any) => moment(params.value).format('DD.MM.YYYY')
        },
        {
            field: 'old_data',
            headerName: 'Было',
            filter: true,
            wrapText: true,
            autoHeight: true,
            hide: true,
            valueFormatter: (params: any) => JSON.stringify(params.value)
        },
        {
            field: 'new_data',
            headerName: 'Стало',
            filter: true,
            wrapText: true,
            autoHeight: true,
            hide: true,
            valueFormatter: (params: any) => JSON.stringify(params.value)
        },
        // {
        //     field: 'new_data',
        //     headerName: 'Стало',
        //     sortable: true,
        //     filter: true,
        //     resizable: true,
        //     valueFormatter: (params: any) => JSON.stringify(`Куст: ${params.value?.bush}
        //     Скв: ${params.value?.wells}`),
        //     cellStyle: {whiteSpace: 'pre-line'},
        //     wrapText: true,
        //     autoHeight: true,
        //     // перенос строки
        //
        // },
    ];

    const getRowId = useMemo(() => {
        return (params: any) => {
            return params.data.pk
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            // width: 120,
            sortable: true,
            resizable: true,
        };

    }, []);


    const fetchData = async () => {
        try {
            // Генерируем UUID для таска драматик
            const requestId = uuidv4();
            // console.time("fetchData"); // Засекаем время выполнения
            const response = await axios.get(API_LOGGING_LIST,
                {
                    params: {
                        range: {valueDateRange},
                        requestId: requestId
                    }
                });
            // console.timeEnd("fetchData"); // Выводим время выполнения в консоль
            if (response.data['status'] === 'OK') {
                let dramatiqResult = false
                // получение результата таск
                const fetchResult = async () => {
                    let resultTask = await GetTaskResult(requestId);
                    setIsLoading(false);
                    if (!resultTask.data) {
                        setTimeout(fetchResult, 2000);
                        return;
                    } else {
                        dramatiqResult = true
                        if (resultTask.data.length > 0) {
                            setRowData(resultTask.data.map((item: any) => {
                                const formattedDate = moment(item.created_at).add(5, 'hours').format('DD.MM.YYYY HH:mm');
                                return {...item, created_at: formattedDate};
                            }))
                        } else {
                            setRowData([])
                        }
                    }
                }

                // циклим пока не придет выполненная таска от драматик
                if (!dramatiqResult) {
                    await fetchResult();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // флаг для первого рендера
    const firstUpdate = useRef(true);

    useEffect(() => {
        // если рендер первый то выходим
        // if (firstUpdate.current) {
        //     firstUpdate.current = false;
        //     console.log('first render dermo')
        //     return;
        // }
        const fetchDataAndReset = async () => {
            setRowData(null)
            setIsLoading(true)
            await fetchData()
            setIsLoading(false)
        }

        fetchDataAndReset()
    }, [valueDateRange]);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
            ],
        };
    }, []);

    const onFirstDataRendered = useCallback((params: any) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const handleDateChange = (value: any) => {
        setValueDateRange(value)
    }
    return (
        <div style={containerStyle}>
            <div className="example-wrapper">
                <div style={{marginBottom: '5px'}}>
                    <span id="eMessage"></span>
                </div>
                <div>
                    <DatePicker
                        label="События за период: "
                        type="date-range"
                        value={valueDateRange}
                        onChange={({value}) => handleDateChange(value)}
                    />
                </div>
                <div style={gridStyle} className="ag-theme-alpine">
                    {isLoading ? <Loader/> :
                        // 'ПЕТУШНЯ ДЕШЕВАЯ'
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            animateRows={true}
                            getRowId={getRowId}
                            defaultColDef={defaultColDef}
                            // onGridReady={onGridReady}
                            statusBar={statusBar}
                            onFirstDataRendered={onFirstDataRendered}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default GridLoggingLogList;