import "./css/headerCmp.css";
import {Theme, presetGpnDefault} from "@consta/uikit/Theme";
import React, {useEffect, useRef, useState} from "react";
import {IconRing} from "@consta/uikit/IconRing";
import {
    Header,
    HeaderButton,
    HeaderLogin,
    HeaderLogo,
    HeaderMenu,
    HeaderModule,
} from "@consta/uikit/Header";
import {ContextMenu} from '@consta/uikit/ContextMenu';
import ExcelUploader from "./importXLS.cmp";
import axios from "axios";

import {useQuery} from "@tanstack/react-query";
import ReportToExcel from "../page/report/component/export.report.func";

export let company: string | null;
export let username: string | null;


type ItemLogout = {
    label: string;
    event?: () => void;
};
const HeaderCmp = (props: { setSelectedMenuItem: (selectedItem: string | null) => void }) => {
    const [orgname, setOrgname] = useState<any>()
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const handleMenuItemClick = (item: string) => {
        props.setSelectedMenuItem(item);
        setSelectedItem(item);
    };
    const [value, setValue] = useState<string | null>(null);
    const [isOpenLogout, setIsOpenLogout] = useState<boolean>(false);
    const handleSearch = (value: string | null) =>
        alert(`Произведен поиск, запрос - ${value} `);
    const ref = useRef(null);
    //
    // const {isPending, error, data, refetch} = useQuery({
    //     queryKey: ['diagramData'],
    //     queryFn: () =>
    //         axios.get(API_REPORTS_REPORT1, {params: {date: '111'}}).then(
    //             (res) => {
    //                 return res.data
    //             },
    //         ),
    // })


    const itemsLogout: ItemLogout[] = [
        {
            label: 'Выйти',
            event: () => {
                handleLogout()
            },
        },
    ];

    let menuItems = [
        {
            label: "Экипаж",
            // href: "#equipage",
            active: selectedItem === "Экипаж",
            onClick: () => handleMenuItemClick("Экипаж"),
        },
        {
            label: "БСИ",
            // href: "#bsi",
            active: selectedItem === "БСИ",
            onClick: () => handleMenuItemClick("БСИ"),
        },
        {
            label: "График",
            // href: "#graph",
            active: selectedItem === "График",
            onClick: () => handleMenuItemClick("График"),
        },
        {
            label: "Обрывы",
            // href: "#graph",
            active: selectedItem === "Обрывы",
            onClick: () => handleMenuItemClick("Обрывы"),
        },
        {
            label: "ППК",
            // href: "#graph",
            active: selectedItem === "ППК",
            onClick: () => handleMenuItemClick("ППК"),
        },

    ];
    if (localStorage.getItem('username') === 'admin') {
        menuItems = [...menuItems, {
            label: "Отчеты",
            // href: "#graph",
            active: selectedItem === "Отчеты",
            onClick: () => handleMenuItemClick("Отчеты"),

        }]
    }
    if (localStorage.getItem('username') === 'admin') {
        menuItems = [...menuItems, {
            label: "Логи",
            // href: "#graph",
            active: selectedItem === "Логи",
            onClick: () => handleMenuItemClick("Логи"),

        }]
    }

    useEffect(() => {
        if (localStorage.getItem('company') && localStorage.getItem('username')) {
            company = localStorage.getItem('company')
            username = localStorage.getItem('username')
            setOrgname(company)
        }
    }, []);


    const getItemOnClickLogout = (item: ItemLogout) => {
        if (item.event) {
            return item.event;
        }
        return () => alert(item.label);
    };

    const handleLogout = () => {
        localStorage.setItem('accessToken', '')
        localStorage.setItem('company', '');
        localStorage.setItem('username', '');
        localStorage.setItem('refreshToken', '');
        window.location.reload()
    }


    return (

        <div className="headerContainer">
            <Theme className="headerCmp" preset={presetGpnDefault}>
                <Header
                    leftSide={
                        <>
                            <HeaderModule>
                                <HeaderLogo>
                                    <img
                                        // src="/pic/Logo_aspo.png" style={{position: 'relative', width: '430px'}}
                                        src="/pic/ASPO_ONLY.png" style={{position: 'relative', width: '150px'}}

                                        alt="Логотип"/>
                                </HeaderLogo>
                            </HeaderModule>

                            <HeaderModule indent="l">
                                <HeaderMenu items={menuItems}/>
                            </HeaderModule>
                        </>
                    }
                    rightSide={
                        <>
                            {localStorage.getItem('username') === 'admin' &&
                                <>
                                    {/*<HeaderModule indent="s">*/}
                                    {/*    <HeaderButton iconLeft={IconRing} onClick={handleReportClick}/>*/}
                                    {/*</HeaderModule>*/}
                                    <HeaderModule indent="s">
                                        <ExcelUploader/>
                                    </HeaderModule>
                                </>
                            }

                            <HeaderModule indent="s">
                                <HeaderLogin
                                    isLogged={true}
                                    personName={orgname}
                                    // personInfo="В другом офисе"
                                    personStatus="available"
                                    onClick={() => setIsOpenLogout(!isOpenLogout)}
                                    // isMinified
                                    ref={ref}
                                />
                                <ContextMenu isOpen={isOpenLogout}
                                             items={itemsLogout}
                                             onClickOutside={() => setIsOpenLogout(false)}
                                             anchorRef={ref}
                                             getItemOnClick={getItemOnClickLogout}/>

                            </HeaderModule>
                        </>
                    }
                />
            </Theme>
        </div>
    )
}

export default HeaderCmp;