import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {LicenseManager} from '@ag-grid-enterprise/core'
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import '../../../component/css/agGridGraph.css';
import axios from "axios";
import {API_BSI_DEL, API_BSI_LIST} from "../../../service/api.endpoint";
import {Loader} from '@consta/uikit/LoaderCanary';
import {company, username} from "../../../component/header.cmp";
import {SnackCmp} from "../../../component/snack.cmp";
import cookieCmp from '../../../component/cookie.cmp';
import ModalBsiPayload from "./modal.bsi.payload";
import ModalBsiDiagram from "./modal.bsi.diagram";
import moment from "moment";
import {DatePicker} from "@consta/uikit/DatePicker";

const license = process.env.REACT_APP_AGGRID
if (license) {
    LicenseManager.setLicenseKey(license);
}

interface AgGridGraphProps {
    newRowData: any;
}

// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

const GridBsiBsiList: React.FC<AgGridGraphProps> = ({newRowData}) => {
    const gridRef = useRef<any>();
    const containerStyle = useMemo(() => ({width: '100%', height: '100%'}), []);
    const gridStyle = useMemo(() => ({height: '75vh', minWidth: '400px'}), []);
    const [rowData, setRowData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAlert, setIsAlert] = useState<"success" | "alert" | "normal" | "system" | "warning">('success')
    const [alertText, setAlertText] = useState<string>('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bsiFileName, setBsiFileName] = useState<string>()
    const [selectInfo, setSelectInfo] = useState<string>('')
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const today = moment().toDate()
    const lastWeek = moment().subtract(7, 'days').toDate();
    const [valueDateRange, setValueDateRange] = useState<[Date?, Date?] | null>([lastWeek, today]);

    // очистка состояния алерта для возможности повторного вызова
    const handleAlertClose = () => {
        setShowAlert(false)
    };

    const handleModalClose = (value: boolean) => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (showAlert) {
            const timeout = setTimeout(() => {
                handleAlertClose();
                setShowAlert(false)
            }, 3000); // Установите желаемое время отображения алерта (в миллисекундах)
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [showAlert]);


    // контекстное меню
    const getContextMenuItems = useCallback((params: any) => {
            var result = []
            if (params.node?.field === "equipage_name") {
                result.push(
                    {
                        name: 'Удалить запись БСИ',
                        action: async () => {
                            const bsiID = params.node.allLeafChildren[0].data.bsi_id
                            try {
                                const additionalData = {username: localStorage.getItem('username')};
                                const response = await axios.delete(`${API_BSI_DEL}/${bsiID}`, {
                                    headers: {
                                        'X-CSRFToken': csrftoken
                                    },
                                    data: additionalData
                                })
                                setShowAlert(true)
                                setIsAlert('success');
                                setAlertText('Запись успешно удалена')
                                onGridReady(null);
                                console.log('Запись успешно удалена:', response.data);
                            } catch
                                (error: any) {
                                setShowAlert(true)
                                setIsAlert('alert');
                                if (error.response.data.message) {
                                    setAlertText(error.response.data.message)
                                } else setAlertText('Ошибка при удалении записи')
                                console.error('Ошибка при удалении записи:', error.response.data);
                            }
                        }
                        ,
                        icon: '<img src="/pic/delIco.png" alt="Del"/>'
                    },
                    'separator',
                    {
                        name: 'Открыть график',
                        action: async () => {
                            setBsiFileName(params.node.allLeafChildren[0].data.bsi_id)
                            setSelectInfo(`${params.node.allLeafChildren[0].data.equipage_name} на ${moment(params.node.allLeafChildren[0].data.bsi_date).format('DD.MM.YYYY')}`)
                            setIsModalOpen(true)
                        }
                        ,
                        icon: '<img src="/pic/chart.png" alt="Chart"/>'
                    },
                    // 'copy',
                    // 'separator',
                    // 'chartRange',
                )
            }
            return result;
        },
        []
    );

    const columnDefs = [
        {
            field: 'bsi_id',
            headerName: 'id',
            hide: true
        },
        {
            field: 'bsi_date',
            headerName: 'Дата',
            enableRowGroup: true,
            enablePivot: true,
            rowGroupIndex: 1,
            initialWidth: 150,
            filter: true,
            hide: true
        },
        {
            field: 'equipage_name',
            headerName: 'Экипаж',
            enableRowGroup: true,
            enablePivot: true,
            rowGroupIndex: 2,
            hide: true,
            filter: true,
            initialWidth: 90,
        },
        {
            field: 'org_name',
            headerName: 'ПО',
            enableRowGroup: true,
            enablePivot: true,
            initialWidth: 100,
            filter: true,
            hide: !!company
        },
        {
            field: 'fio',
            headerName: 'ФИО ответственного',
            enableRowGroup: true,
            enablePivot: true,
            filter: true,

        },
        {
            field: 'date_log',
            headerName: 'Дата в логах',
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
        },
        {
            field: 'descent_start',
            headerName: 'Начало спуска',
            enableRowGroup: true,
            enablePivot: true,
        },
        {
            field: 'descent_end',
            headerName: 'Завершение спуска',
            enableRowGroup: true,
            enablePivot: true,
            hide: true
        },
        {
            field: 'descent_total',
            headerName: 'Время спуска',
            enableRowGroup: true,
            enablePivot: true,
        },
        {
            field: 'depth',
            headerName: 'Глубина',
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
        },
        {
            field: 'lift_start',
            headerName: 'Начало подьема',
            enableRowGroup: true,
            enablePivot: true,
            hide: true
        },
        {
            field: 'lift_end',
            headerName: 'Завершение подьема',
            enableRowGroup: true,
            enablePivot: true,
            hide: true
        },
        {
            field: 'lift_total',
            headerName: 'Время подьема',
            enableRowGroup: true,
            enablePivot: true,
        },
    ];

    const getRowId = useMemo(() => {
        return (params: any) => {
            return params.data.id
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            // width: 120,
            sortable: true,
            resizable: true,
            flex: 1
        };
    }, []);

    const onFirstDataRendered = useCallback((params: any) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const fetchData = async () => {
        try {
            // console.time("fetchData"); // Засекаем время выполнения
            const response = await axios.get(API_BSI_LIST, {
                params: {
                    name: localStorage.getItem('username'),
                    company: company,
                    range: {valueDateRange}
                }
            });
            // console.timeEnd("fetchData"); // Выводим время выполнения в консоль
            const result = response.data.map((item: any) => ({
                ...item,
                bsi_date: moment(item.bsi_date, 'DD-MM-YYYY').toDate(),
                date_log: moment(item.date_log, 'DD-MM-YYYY').toDate()
            }));
            setIsLoading(false);
            return result
        } catch (error) {
            console.log(error);
        }
    };

    const onGridReady = async (params: any) => {
        setRowData(await fetchData())
    };

    useEffect(() => {
        setIsLoading(true)
        onGridReady(null); // Вызываем функцию при монтировании компонента
    }, [newRowData, valueDateRange]);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
            ],
        };
    }, []);

    const handleDateChange = (value: any) => {
        setValueDateRange(value)
    }

    return (
        <div style={containerStyle}>
            <div className="example-wrapper">
                <div style={{marginBottom: '5px'}}>
                    <span id="eMessage"></span>
                </div>
                <div style={gridStyle} className="ag-theme-alpine">
                    <div>
                        <DatePicker
                            label="Диапазон выборки БСИ"
                            type="date-range"
                            value={valueDateRange}
                            onChange={({value}) => handleDateChange(value)}
                        />
                    </div>
                    {isLoading ? <Loader/> :
                        // 'ПЕТУШНЯ ДЕШЕВАЯ'
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            animateRows={true}
                            getRowId={getRowId}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            statusBar={statusBar}
                            onFirstDataRendered={onFirstDataRendered}
                            rowGroupPanelShow={'always'}
                            allowContextMenuWithControlKey={true}
                            getContextMenuItems={getContextMenuItems}
                        />
                    }
                </div>
                {showAlert && <SnackCmp alertText={alertText} alertType={isAlert}/>}

            </div>
            {bsiFileName && <ModalBsiDiagram isOpen={isModalOpen}
                                             onClose={handleModalClose}
                                             fileName={bsiFileName}
                                             equipageInfo={selectInfo}/>}
        </div>
    );
};

export default GridBsiBsiList;