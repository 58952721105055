import React from 'react';
import {BrowserRouter, createBrowserRouter, RouterProvider} from "react-router-dom";
import PageMain from "./page/page.main";
import {Theme, presetGpnDefault} from '@consta/uikit/Theme';
import {PageLogin} from "./page/login/page.login";
import WrapperAuthCmp from "./component/wrapper.auth.cmp";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

const queryClient = new QueryClient()

const App = () => {

    const router = createBrowserRouter([
        {
            path: "/main",
            element: <PageMain loading={false}/>
        },
        {
            path: "/login",
            element: <PageLogin/>
        },
    ]);

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Theme preset={presetGpnDefault}>
                    <WrapperAuthCmp>
                        <RouterProvider router={router}/>
                    </WrapperAuthCmp>
                </Theme>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
