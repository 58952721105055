import React from "react";

function dayWidthBrowser() {
    const bw = navigator.userAgent.includes('(X11; Linux x86_64)')
    if (bw) {
        return 42
    } else return 37
}

export const width = dayWidthBrowser()

export const CustomBushCellRenderer: React.FC<any> = ({value}) => {
    if (value === 0) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <img src="/pic/graph_ico/0.png" alt="Plan" style={{width: '33px', height: '27px'}}/>
            </div>
        );
    }
    if (value === 1) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <img src="/pic/graph_ico/1.png" alt="Plan" style={{width: '33px', height: '27px'}}/>
            </div>
        );
    }
    if (value === 2) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <img src="/pic/graph_ico/2.png" alt="Plan" style={{width: '33px', height: '27px'}}/>
            </div>
        );
    }
    if (value === 3) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <img src="/pic/graph_ico/3.png" alt="Plan" style={{width: '33px', height: '27px'}}/>
            </div>
        );
    }
    if (value === 4) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <img src="/pic/graph_ico/4.png" alt="Plan" style={{width: '33px', height: '27px'}}/>
            </div>
        );
    }

    if (value === 5) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <img src="/pic/graph_ico/5.png" alt="Plan" style={{width: '33px', height: '27px'}}/>
            </div>
        );
    }
    return null;
};

export const columnDefs = [
    {
        field: 'workDate',
        headerName: 'Дата',
        hide: true,
        enablePivot: true,
    },
    {
        field: 'location',
        headerName: 'Локация',
        initialWidth: 70,
        sortable: true,
        filter: true,
        resizable: true,
        enablePivot: true,
        rowGroupIndex: 1,
        hide: true,

    },
    {
        field: 'deposit',
        headerName: 'Месторождение',
        initialWidth: 90,
        sortable: true,
        filter: true,
        resizable: true,
        enablePivot: true,
        hide: true,
    },
    {
        field: 'dns',
        headerName: 'ДНС',
        initialWidth: 70,
        sortable: true,
        filter: true,
        enablePivot: true,
        enableRowGroup: true,
        hide: true,
    },
    {
        field: 'bush',
        headerName: 'Куст',
        initialWidth: 70,
        sortable: true,
        enablePivot: true,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        floatingFilter: true,
    },
    {
        field: 'wells.name',
        headerName: 'Скважина',
        initialWidth: 90,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        floatingFilter: true,
    },
    {
        field: 'org',
        headerName: 'Исполнитель',
        minWidth: 100,
        initialWidth: 100,
        sortable: true,
        filter: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        hide: localStorage.getItem('username') !== 'admin'
    },
    {
        field: 'diam',
        headerName: 'Диаметр Ø',
        initialWidth: 40,
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        enableRowGroup: true,
        floatingFilter: true,
        enablePivot: true,
    },
    {
        field: 'd1.plan',
        headerName: '1',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd1.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd2.plan',
        headerName: '2',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd2.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd3.plan',
        headerName: '3',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd3.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd4.plan',
        headerName: '4',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd4.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd5.plan',
        headerName: '5',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd5.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd6.plan',
        headerName: '6',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd6.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd7.plan',
        headerName: '7',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd7.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd8.plan',
        headerName: '8',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd8.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd9.plan',
        headerName: '9',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd9.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd10.plan',
        headerName: '10',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd10.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd11.plan',
        headerName: '11',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd11.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd12.plan',
        headerName: '12',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd12.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd13.plan',
        headerName: '13',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd13.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd14.plan',
        headerName: '14',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd14.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd15.plan',
        headerName: '15',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd15.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd16.plan',
        headerName: '16',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd16.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd17.plan',
        headerName: '17',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd17.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd18.plan',
        headerName: '18',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd18.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd19.plan',
        headerName: '19',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd19.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd20.plan',
        headerName: '20',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd20.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd21.plan',
        headerName: '21',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd21.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd22.plan',
        headerName: '22',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd22.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd23.plan',
        headerName: '23',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd23.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd24.plan',
        headerName: '24',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd24.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd25.plan',
        headerName: '25',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd25.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd26.plan',
        headerName: '26',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd26.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd27.plan',
        headerName: '27',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd27.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd28.plan',
        headerName: '28',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd28.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd29.plan',
        headerName: '29',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd29.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd30.plan',
        headerName: '30',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd30.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'd31.plan',
        headerName: '31',
        width: width,
        maxWidth: width,
        resizable: false,
        suppressMenu: true,
        sortable: true,
        tooltipField: 'd31.plan',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        cellRenderer: CustomBushCellRenderer
    },
    {
        field: 'total_plan',
        headerName: 'План',
        // width: 50,
        // maxWidth: 50,
        initialWidth: 55,
        resizable: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        aggFunc: 'sum'
    },
    {
        field: 'total_fact',
        headerName: 'Факт',
        // width: 50,
        // maxWidth: 50,
        initialWidth: 50,
        resizable: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        aggFunc: 'sum'
    },
    {
        field: 'bsi_level',
        headerName: 'БСИ',
        // width: 70,
        // maxWidth: 70,
        initialWidth: 70,
        resizable: true,
        sortable: true,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        floatingFilter: true,
    },
    {
        field: 'level',
        headerName: 'Степень',
        // width: 70,
        // maxWidth: 70,
        initialWidth: 70,
        resizable: true,
        sortable: true,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        floatingFilter: true,
    },
       {
        field: 'comment',
        headerName: 'Примечание',
        // width: 70,
        // maxWidth: 70,
        initialWidth: 70,
        resizable: true,
        sortable: true,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        floatingFilter: true,
    },
];