import {Modal} from '@consta/uikit/Modal';
import {SnackCmp} from "../../../component/snack.cmp";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Button} from "@consta/uikit/Button";
import React, {useEffect, useState} from "react";
import {Attachment} from '@consta/uikit/Attachment';
import '../../css/pageBsi.css';
import axios from "axios";
import {API_BSI_PAYLOAD, API_BSI_PROCESSING, API_BSI_SIZE_CHECK, API_URL_DICT} from "../../../service/api.endpoint";
import {company} from "../../../component/header.cmp";
import moment from "moment/moment";
import {IconTrash} from "@consta/uikit/IconTrash";
import {v4 as uuidv4} from 'uuid';
import cookieCmp from '../../../component/cookie.cmp';
import {SnackBarItemStatus} from "@consta/uikit/SnackBar";


const username = localStorage.getItem('username')
type SkkNameType = "CKK-Tehno" | "Nedrakam" | 'noname'
export type Item = {
    label: string;
    id: string;
};

export type ProcessingType = {
    data: string;
    equipage: string;
    fio: string;
    status: SnackBarItemStatus
};

type DictType = {
    pk: string,
    name: string,
    comment: { name: string, pk: string }[],
    level: { name: string, pk: string }[],
    equipage: { name: string, pk: string }[],
}

interface ModalComponentProps {
    isOpen: boolean;
    onClose: any;
    nodeData: any
    files: any
}


// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

// проверка на изменение файла
function checkLastModify(contents: any, lastModify: string, skkName: SkkNameType) {
    if (skkName === 'CKK-Tehno') {
        for (let i = 1; i <= 5; i++) {
            try {
                const firstLine = contents.split('\n')[0];
                const lastStringDate = moment(contents.split('\n').slice(-i)[0], 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
                // проверка на отсутствующую дату изменения файла для версий скк 310 и только для nicngt
                if (moment(lastModify, 'DD.MM.YYYY') < moment('01.01.2020', 'DD.MM.YYYY') && username === 'nicngt') {
                    return true;
                    // проверка для версий скк 310 экипаж югра сервис 458, просто пропускаем его, пока без логики
                } else if (firstLine.includes('458')) {
                    return true;
                } else {
                    const isModify = lastModify === lastStringDate;
                    if (isModify) {
                        return true;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        return false; // added return statement to handle case when no match is found
    } else if (skkName === 'Nedrakam') {
        // todo проверку на изменение файла придумать
        return true
    }
    return false

}

function lastStringDate(contents: any) {
    for (let i = 1; i <= 5; i++) {
        try {
            // console.log(contents)
            const lst = moment(contents.split('\n').slice(-i)[0], 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
            if (lst && lst !== 'Invalid date') {
                return lst
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const contents = reader.result;
            resolve(contents);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsText(file);
    });
};

const ModalBsiPayload: React.FC<ModalComponentProps> = ({files, isOpen, onClose}) => {

    const [bsiFiles, setBsiFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const [itemsEquipage, setItemsEquipage] = useState<Item[]>([]);
    const [isAlert, setIsAlert] = useState<boolean>(false)

    const [valueEquipage, setValueEquipage] = useState(Array(bsiFiles.length).fill(''));
    const [valueFIO, setValueFIO] = useState(Array(bsiFiles.length).fill(''));
    const [lastString, setLastString] = useState(Array(bsiFiles.length).fill(''));
    const [skkName, setSkkName] = useState(Array(bsiFiles.length).fill(''));
    const [bsiFileSize, setBsiFileSize] = useState(Array(bsiFiles.length).fill(''));
    const [bsiFileContent, setBsiFileContent] = useState<string[]>(Array(bsiFiles.length).fill(''));
    const [bsiDate, setBsiDate] = useState<string[]>(Array(bsiFiles.length).fill(''));
    const [processingData, setProcessingData] = useState<ProcessingType[]>([])
    const [isFinalProcessing, setIsFinalProcessing] = useState<boolean>(false)


    const handleConfirm = (value: any) => {
        onClose(value); // Передаем значение в родительский компонент
    };


    // delete context button
    const handleDeleteFile = (index: number) => {

        const newBsiFiles = [...bsiFiles];
        newBsiFiles.splice(index, 1);
        setBsiFiles(newBsiFiles);

        // обновляем индексы значений в массивах
        const updatedValueEquipage = [...valueEquipage];
        updatedValueEquipage.splice(index, 1);
        setValueEquipage(updatedValueEquipage);

        const updatedValueFIO = [...valueFIO];
        updatedValueFIO.splice(index, 1);
        setValueFIO(updatedValueFIO);

        const updateBsiFileContent = [...bsiFileContent];
        updateBsiFileContent.splice(index, 1);
        setBsiFileContent(updateBsiFileContent);

        const updateLastStringDate = [...lastString];
        updateLastStringDate.splice(index, 1);
        setLastString(updateLastStringDate);

        const updateSkkName = [...skkName];
        updateSkkName.splice(index, 1);
        setSkkName(updateSkkName);


        if (newBsiFiles.length < 1) {
            onClose(null)
        }
    };


    //save button
    const handleSaveButton = async () => {
        setIsSaving(true);
        setProcessingData([])
        try {
            const mergedArraysBsi = bsiFiles.map((file, index) => {
                const dateString = lastString[index];
                return {file, dateString};
            });

            mergedArraysBsi.sort((a: any, b: any) => {
                const dateA: any = new Date(a.dateString);
                const dateB: any = new Date(b.dateString);
                return dateA - dateB;
            });

            const promises: any = [];
            let i = 0

            for (const file of mergedArraysBsi) {
                const formData = new FormData();
                const uuid = uuidv4();
                formData.append('id', uuid);
                formData.append('bsi_file', file.file, `${uuid}.txt`);
                formData.append('date', bsiDate[i]);
                formData.append('equipage', valueEquipage[i].id);
                company && formData.append('org', company);
                formData.append('fio', valueFIO[i]);
                formData.append('file_size', bsiFileSize[i]);
                formData.append('last_string_date', lastString[i]);
                // @ts-ignore
                formData.append('username', username);

                const payloadResult = await axios.post(API_BSI_PAYLOAD, formData, {
                    headers: {
                        'X-CSRFToken': csrftoken
                    }
                });
                if (payloadResult.status === 201) {
                    const newData: ProcessingType = {
                        equipage: valueEquipage[i].label,
                        data: bsiDate[i],
                        fio: valueFIO[i],
                        status: 'normal'
                    };
                    try {
                        await axios.post(API_BSI_PROCESSING, {
                            params: {
                                equipage: valueEquipage[i].id,
                                org: company,
                                date: bsiDate[i],
                                skk_name: skkName[i]
                            }
                        }, {
                            headers: {
                                'X-CSRFToken': csrftoken
                            }
                        });
                        newData['status'] = 'success'
                        setProcessingData(prevData => [...prevData, newData]);
                    } catch (error) {
                        newData['status'] = 'alert'
                        setProcessingData(prevData => [...prevData, newData]);
                    }
                }
                i = i + 1
            }
            ;
            await Promise.all(promises);
            setIsFinalProcessing(true)
            onClose(bsiFiles);
            setValueFIO([])
            setValueEquipage([])
        } catch (error) {
            setIsAlert(true);
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        setIsAlert(true);
        setIsFinalProcessing(false)
    }, [isFinalProcessing]);

    useEffect(() => {
        setBsiFiles(files)
    }, [files]);

    function bsiCheck(repeatData: [], isModify: boolean) {
        if (repeatData.length > 0) {
            return "Этот файл уже был загружен!"
        } else if (!isModify) {
            return "БСИ-ФАЙЛ ИЗМЕНЕН!"
        } else {
            return undefined
        }
    }

    useEffect(() => {
        bsiFiles.forEach((file: any, index) => {
                if (file) {
                    readFile(file)
                        .then(async (contents: any) => {
                                let SKK: SkkNameType = 'noname'
                                let dateElement: number = 6 // 6 prosto tak
                                let dataString;
                                try {
                                    // todo fs/promise
                                    // const fileStats = await stat(file);
                                    // const creationDate = fileStats.birthtime;

                                    // определяем что за производитель скк
                                    // ТЕХНО?
                                    const isSKKNedrakam = (contents: string) => {
                                        const lines = contents.split('\n');
                                        const fourthLine = lines[4];
                                        // серийные номера недракамовских установок из шапки БСИ файла
                                        const bsiCodes = ['670', '841', '591', '824', '655', '846'];
                                        return bsiCodes.some(code => fourthLine.includes(code));
                                    };

                                    if (contents.split('\n')[0].includes("Tehno")) {
                                        SKK = "CKK-Tehno"
                                        dateElement = 3
                                        // } else if (contents.split('\n')[3].includes("SPS-7" || "СПС-7")) {
                                    } else if (isSKKNedrakam(contents)) {
                                        SKK = "Nedrakam"
                                        dateElement = 5
                                    }

                                    // смотрим какая дата в файле
                                    dataString = contents.split('\n')[dateElement];
                                    const fileDate = moment(dataString.split(' ')[0], 'DD.MM.YYYY').format('YYYY-MM-DD');
                                    const lastModify = moment(file.lastModifiedDate, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm:ss')
                                    // const fileCreateDate = moment(file.lastModified).format('DD.MM.YYYY HH:mm:ss')


                                    const isModify: boolean = checkLastModify(contents, lastModify, SKK);
                                    if (file.size < 4000) {
                                        throw new Error("Empty File");
                                    }


                                    axios.get(API_BSI_SIZE_CHECK, {params: {day: fileDate, file_size: file.size}})
                                        .then((response) => {
                                            const repeatData: [] = response.data;
                                            setBsiFileContent(prevContent => {
                                                const updatedContent: any = [...prevContent];
                                                updatedContent[index] = bsiCheck(repeatData, isModify);
                                                return updatedContent;
                                            });
                                        });

                                    setBsiDate(prevContent => {
                                        const updatedContent = [...prevContent];
                                        updatedContent[index] = fileDate;
                                        return updatedContent;
                                    });
                                    setBsiFileSize(prevContent => {
                                        const updatedContent = [...prevContent];
                                        updatedContent[index] = file.size
                                        return updatedContent;
                                    });
                                    setLastString(prevContent => {
                                        const updatedContent = [...prevContent];
                                        updatedContent[index] = lastStringDate(contents)
                                        return updatedContent;
                                    });
                                    setSkkName(prevContent => {
                                        const updatedContent = [...prevContent];
                                        updatedContent[index] = SKK
                                        return updatedContent;
                                    });

                                } catch
                                    (error) {
                                    setBsiFileContent(prevContent => {
                                        const updatedContent = [...prevContent];
                                        updatedContent[index] = 'Нет данных или файл БСИ поврежден!';
                                        return updatedContent;
                                    });
                                    dataString = ''
                                }

                            }
                        )
                        .catch((error) => {
                            console.error('Ошибка чтения файла:', error);
                        });
                }
            }
        )
        ;
    }, [bsiFiles]);


    useEffect(() => {
        if (bsiFileContent.filter(item => item !== undefined).length > 0) {
            setIsSaving(true)
        } else {
            if (bsiFiles.length ===
                valueEquipage.filter(item => item !== undefined).filter(item => item !== null).length) {
                setIsSaving(false)
            } else {
                setIsSaving(true)
            }

        }
    }, [bsiFileContent, bsiFiles, valueEquipage]);


    useEffect(() => {
        setIsAlert(false)
        setProcessingData([])
        axios
            .get<DictType>(API_URL_DICT)
            .then((response) => {
                const data: DictType = response.data;
                setItemsEquipage(company === '' ? data.equipage.map((item, index) => ({
                    label: item.name,
                    id: item.pk,
                })) : data.equipage.filter((item: any) => item.org.name === company).map((item, index) => ({
                    label: item.name,
                    id: item.pk,
                })));
            });
    }, [])


    return (
        <>
            <Modal
                className="modal_bsi"
                isOpen={isOpen}
                hasOverlay
                // onClickOutside={onClose}
                onEsc={onClose}
            >
                <Grid cols="3" gap="xs">
                    {bsiFiles.map((file: File, index: number) => {
                        return (
                            <React.Fragment key={`key_${index}`}>
                                <GridItem col={'1'} key={`grid1_${index}`}>
                                    <Attachment
                                        key={`attach_${index}`}
                                        fileName={file.name}
                                        fileExtension="txt"
                                        fileDescription={`${Math.round(file.size / 1000)} КБ ${moment(Number(file.lastModified)).format('DD.MM.YYYY, HH:mm')}`}
                                        // errorText={Math.round(file.size / 1000) === 0 ? "Нет данных" : undefined}
                                        errorText={bsiFileContent[index]}
                                    />
                                </GridItem>
                                <GridItem col={'1'} key={`grid2_${index}`}>
                                    <Combobox
                                        key={`equipage_${index}`}
                                        items={itemsEquipage}
                                        value={valueEquipage[index]}
                                        onChange={({value}) => {
                                            const updatedValueEquipage = [...valueEquipage];
                                            updatedValueEquipage[index] = value;
                                            setValueEquipage(updatedValueEquipage);
                                        }}
                                        placeholder="Экипаж"
                                        required={true}
                                    />
                                </GridItem>
                                <GridItem key={`grid3_${index}`}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <TextField
                                            key={`fio_${index}`}
                                            id={`fioID_${index}`}
                                            style={{width: '100%'}}
                                            value={valueFIO[index]}
                                            onChange={({value}) => {
                                                const updatedValueFIO = [...valueFIO];
                                                updatedValueFIO[index] = value;
                                                setValueFIO(updatedValueFIO);
                                            }}
                                            type="text"
                                            placeholder="Укажите ФИО"
                                            autoComplete='on'
                                            required={true}
                                        />
                                        <IconTrash
                                            key={`trash_${index}`}
                                            className="iconTrash"
                                            onClick={() => {
                                                handleDeleteFile(index);
                                            }}
                                        />
                                    </div>
                                </GridItem>
                            </React.Fragment>
                        );
                    })}
                    <GridItem col={'2'}>
                        <div style={{position: 'absolute', right: '20px'}}>
                            <Button
                                size="m"
                                view="primary"
                                label="Сохранить"
                                width="default"
                                onClick={() => {
                                    setIsAlert(false);
                                    handleSaveButton();
                                }}
                                disabled={isSaving} // Добавляем блокировку кнопки во время сохранения
                            />
                            <Button
                                className='cancel'
                                size="m"
                                view="primary"
                                label="Отменить"
                                width="default"
                                onClick={() => {
                                    setValueEquipage([])
                                    setValueFIO([])
                                    handleConfirm(false);
                                }}
                            />
                        </div>
                    </GridItem>
                </Grid>
            </Modal>
            {(isAlert) &&
                <SnackCmp alertText={''} processingData={processingData} alertType={'normal'}/>}
        </>
    )
};

export default ModalBsiPayload;
