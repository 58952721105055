import {Grid, GridItem} from "@consta/uikit/Grid";
import React, {useEffect} from "react";
import "../css/pageEquipage.css";
import GridLoggingLogList from "./component/grid.logging.logList";


const PageLogging = () => {
    useEffect(() => {
        document.title = 'АСПО.Контроль | Логи';
    }, []);


    return (
        <>
            <Grid className="gridMain" cols="1" gap="xs">
                <GridItem>
                    <GridLoggingLogList/>
                </GridItem>
            </Grid>

        </>
    )
}

export default PageLogging;