import React, {useEffect, useState} from "react";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from '@consta/uikit/Text';
import {DateTime} from '@consta/uikit/DateTime';
import {Button} from '@consta/uikit/Button';
import {IconLineAndBarChart} from "@consta/uikit/IconLineAndBarChart";
import ReportToExcel from "./component/export.report.func";
import moment from "moment";
import {Loader} from "@consta/uikit/LoaderCanary";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {API_REPORTS_EVENT} from "../../service/api.endpoint";
import {IconEye} from "@consta/uikit/IconEye";
import "./component/css/css.page.report.css";
// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJSChart = CanvasJSReact.CanvasJSChart;


function barGraphFunc(data: any) {
    const options = {
        animationEnabled: true,
        exportEnabled: true,
        title: {
            text: "сводный 2",
            fontFamily: "verdana"
        },
        axisY: {
            title: "in Eur",
            includeZero: true,
            prefix: "€",
            suffix: "k"
        },
        toolTip: {
            shared: true,
            reversed: true
        },
        legend: {
            verticalAlign: "center",
            horizontalAlign: "right",
            reversed: true,
            cursor: "pointer",
            // itemclick: this.toggleDataSeries
        },
        data: [
            {
                type: "stackedColumn",
                name: "General",
                showInLegend: true,
                yValueFormatString: "#,###k",
                dataPoints: [
                    {label: "НИЦ", y: 14},
                    {label: "ЮГРА", y: 12},
                    {label: "ЭТАЛОН", y: 14},
                ]
            },
            {
                type: "stackedColumn",
                name: "Marketing",
                showInLegend: true,
                yValueFormatString: "#,###k",
                dataPoints: [
                    {label: "НИЦ", y: 13},
                    {label: "ЮГРА", y: 13},
                    {label: "ЭТАЛОН", y: 15},
                ]
            },
            {
                type: "stackedColumn",
                name: "Факт",
                showInLegend: true,
                yValueFormatString: "#,###k",
                dataPoints: [
                    {label: "НИЦ", y: 225},
                    {label: "ЮГРА", y: 152},
                    {label: "ЭТАЛОН", y: 320},
                ]
            },
            {
                type: "stackedColumn",
                name: "План",
                showInLegend: true,
                yValueFormatString: "#,###k",
                dataPoints: [
                    {label: "НИЦ", y: 500},
                    {label: "ЮГРА", y: 430},
                    {label: "ЭТАЛОН", y: 525},
                ]
            }
        ]
    }
    return options
}


function stackedBarFunc(data: any) {

    const orgStyle = [
        {color: '#1caada'},
        {color: 'rgb(0,121,194)'},
        {color: '#67c5ff'},
    ]
    let stackedBarData: any[] = []
    data.data.map((el: any, index: number) => {
        const selectedData =
            {
                type: "stackedBar100",
                color: orgStyle[index].color,
                name: el['company'],
                showInLegend: true,
                indexLabel: "{y}",
                indexLabelFontColor: "white",
                // yValueFormatString: "#,###'%'",
                dataPoints: [
                    {label: "Всего скважин", y: el['total_plan']},
                    {label: "Охвачено скважин", y: el['day_plan_work']},
                    {label: "Непроходов", y: el['no_passage']},
                    {label: "ЛГПП", y: el['liquidation']},
                    {label: "Непроходов по орг.", y: el['by_org_reason_data'].length},
                    {label: "План проходов", y: el['day_plan_total']},
                    {label: "Факт проходов", y: el['day_fact_total']},
                    {label: "Отставание", y: el['day_differ_total']},
                ]
            }
        stackedBarData = [...stackedBarData, selectedData]

    })
    return stackedBarData
}

const PageReport = () => {
    const [valueRep1, setValueRep1] = useState<Date | undefined>();
    const [rep1Loading, setRep1Loading] = useState<boolean>(false)
    const [eventsReport1, setEventsReport1] = useState<any>([])
    const [dataReport1, setDataReport1] = useState<any>([])
    const [selectedDate, setSelectedDate] = useState("");
    const [isData, setIsData] = useState<boolean>(false)
    // const [stackedBarData, setStackedBarData] = useState<any>([])
    const [stackedBar, setStackedBar] = useState<any>(null)
    const [updatedAt, setUpdatedAt] = useState<string | undefined>(undefined)

    useEffect(() => {
        document.title = 'АСПО.Контроль | Отчеты';
    }, []);

    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['reportEvevntData'],
        queryFn: async () => {
            return await axios.get(API_REPORTS_EVENT, {params: {name: 'Report1'}}).then(
                (res) => {
                    setEventsReport1([])
                    res.data.map((el: any) => {
                        const date = moment(el['date'], 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        setEventsReport1((prevState: any) => [...prevState, moment(date).toDate()]);
                        setDataReport1((prevState: any) => [...prevState, {
                            date: moment(date).toDate(),
                            data: el['data'],
                            updated_at: el['updated_at']
                        }]);
                    })
                    return res.data
                },
            )
        },
    })

    const handleReportClick = () => {
        setRep1Loading(true)
        ReportToExcel(moment(valueRep1).format('DD.MM.YYYY'), setRep1Loading)
    }

    const handleReportViewClick = () => {
        const formattedValue = moment(selectedDate).add(5, 'hours').toDate()
        const formattedValueTime = new Date(formattedValue).getTime();
        const masData = dataReport1.map((el: any) => {
            return {
                date: new Date(el.date).getTime(),
                data: el.data
            };
        });
        const data = masData.find((el: any) => el.date === formattedValueTime)
        setRep1Loading(true)
        console.log(data)
        ReportToExcel(moment(valueRep1).format('DD.MM.YYYY'), setRep1Loading, data)
    }

    const handleDateChange = (value: any) => {
        setSelectedDate(value);
        setValueRep1(value)
        const formattedValue = moment(value).add(5, 'hours').toDate()
        const formattedValueTime = new Date(formattedValue).getTime();
        const masDates = eventsReport1.map((date: any) => new Date(date).getTime());
        if (masDates.includes(formattedValueTime)) {
            setIsData(true)
        } else {
            setIsData(false)
        }

        const masData = dataReport1.map((el: any) => {
            return {
                date: new Date(el.date).getTime(),
                data: el.data,
                updated_at: el.updated_at
            };
        });
        const data = masData.find((el: any) => el.date === formattedValueTime)
        if (data?.updated_at) {
            setUpdatedAt(moment(data.updated_at).add(5, 'hours').format('DD.MM.YYYY HH:mm'))
        } else {
            setUpdatedAt(undefined)
        }

        data ?
            setStackedBar({
                title: {
                    text: "Сводная информация"
                },
                toolTip: {
                    shared: true
                },
                legend: {
                    verticalAlign: "top"
                },
                // axisY: {
                // 	suffix: "%"
                // },
                data: stackedBarFunc(data)
            }) :
            setStackedBar(null)


    }


    useEffect(() => {
        refetch(); // Вызов метода refetch
    }, [rep1Loading]);


    useEffect(() => {
        if (eventsReport1.length > 0) {
            const currentDate = moment().format('YYYY-MM-DDT05:00:00.000Z');
            const formattedValue = moment(currentDate).toDate()
            const formattedValueTime = new Date(formattedValue).getTime();
            const masDates = eventsReport1.map((date: any) => new Date(date).getTime());
            if (masDates.includes(formattedValueTime)) {
                setIsData(true)
            } else {
                setIsData(false)
            }
        }
    }, [eventsReport1]);


    return (
        <>
            <Grid className="gridMain" cols="4" gap="2xs"
                  breakpoints={{
                      l: {
                          cols: 4
                      },
                      m: {
                          cols: 6,
                          gap: '2xl'
                      },
                      s: {
                          cols: 2,
                          gap: '2xs'
                      },
                      xs: {
                          cols: 1,
                      }
                  }}
            >
                {/*<GridItem col={1}>*/}
                {/*    <Text size="3xl">Просто текст</Text>*/}
                {/*</GridItem>*/}
                {/*Report1*/}
                <GridItem className='report_one'
                          breakpoints={{
                              m: {
                                  col: 2,
                              },
                              s: {
                                  col: 1
                              },
                              xs: {
                                  col: 1,
                                  colStart: 1,
                                  rowStart: 1,
                                  order: -1
                              }


                          }}
                >
                    {!rep1Loading ?
                        <>
                            <Text size="2xl" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Отчет
                                №1</Text>
                            <div
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <DateTime type="date"
                                          events={eventsReport1}
                                          onChange={({value}) => handleDateChange(value)}/>
                            </div>
                            <div
                                style={{display: 'flex', alignItems: 'center'}}>

                                <Button label="Сформировать" view="secondary"
                                        iconLeft={isData ? undefined : IconLineAndBarChart}
                                        style={{width: isData ? '45%' : '95%', margin: '5px'}}
                                        onClick={handleReportClick}/>

                                {isData && <Button label="Открыть" view="secondary"
                                                   iconLeft={IconEye}
                                                   style={{width: '45%', margin: '5px'}}
                                                   onClick={handleReportViewClick}/>}
                            </div>

                            {updatedAt && <div style={{textAlign: 'center'}}>
                                <Text size='xs' view='ghost'>Сформирован: {updatedAt}</Text></div>}
                        </>
                        : <Loader/>}
                </GridItem>
                {stackedBar && <GridItem
                    style={{
                        minWidth: '300px',
                        borderRadius: '10px',
                        background: 'yellow'
                    }}
                    breakpoints={{
                        l: {
                            col: 3,
                            colStart: 2,
                            rowStart: 1
                        },
                        m: {
                            col: 4,
                            colStart: 3,
                            rowStart: 1
                        },
                        s: {
                            col: 2,
                            rowStart: 2,
                            colStart: 1
                        },
                        xs: {
                            col: 1,
                            colStart: 1,
                            rowStart: 2,
                            order: 1
                        }


                    }}>
                    <CanvasJSChart options={stackedBar}
                        /* onRef={ref => this.chart = ref} */
                    />
                </GridItem>}
                {/*<GridItem>*/}
                {/*    <CanvasJSChart options={barGraphFunc(null)}/>*/}
                {/*</GridItem>*/}
            </Grid>
        </>
    );
}

export default PageReport;