import {Grid, GridItem} from "@consta/uikit/Grid";
import React, {useEffect, useState} from "react";
import "../css/pageGraph.css";
import GridGraphGraphList from "./component/grid.graph.graphList";
import {startOfMonth} from "date-fns";
import {ChoiceGroup} from '@consta/uikit/ChoiceGroup';
import {DatePicker} from "@consta/uikit/DatePicker";
import AgExportCmp from "./component/export.graph.func";
import {cn} from "@consta/uikit/__internal__/src/uiKit/cn";
import moment from "moment/moment";
import axios from "axios";
import {API_GRAPH_EXIST_MONTH} from "../../service/api.endpoint";
import {company} from "../../component/header.cmp";


const PageGraph = () => {
    const [valueDate, setValueDate] = useState<Date | null>(null);
    const [rowDataTransfer, setRowDataTransfer] = useState<any>(null);
    const [valueMonthChoice, setValueMonthChoice] = useState<Item | null>(null);
    const [filterDate, setFilterDate] = useState<Date | 'loading' | null>(null)
    const [graphLoading, setGraphLoading] = useState<boolean>(false)
    type Item = { name: string; disabled?: boolean, numeric?: string };
    const cnChoiceGroupExampleAdaptiveScroll = cn(
        'ChoiceGroupExampleAdaptiveScroll',
    );
    const [itemsMonth, setItemsMonth] = useState<Item[]>([
        {name: 'январь', numeric: '01', disabled: true},
        {name: 'февраль', numeric: '02', disabled: true},
        {name: 'март', numeric: '03', disabled: true},
        {name: 'апрель', numeric: '04', disabled: true},
        {name: 'май', numeric: '05', disabled: true},
        {name: 'июнь', numeric: '06', disabled: true},
        {name: 'июль', numeric: '07', disabled: true},
        {name: 'август', numeric: '08', disabled: true},
        {name: 'сентябрь', numeric: '09', disabled: true},
        {name: 'октябрь', numeric: '10', disabled: true},
        {name: 'ноябрь', numeric: '11', disabled: true},
        {name: 'декабрь', numeric: '12', disabled: true},
        {name: 'экспорт', numeric: 'exp', disabled: true},

    ])
    useEffect(() => {
        document.title = 'АСПО.Контроль | График';
    }, []);

    useEffect(() => {
        const currentDate = startOfMonth(new Date());
        setValueDate(currentDate);
    }, []);

    useEffect(() => {
        if (valueDate) {
            axios.get(API_GRAPH_EXIST_MONTH, {params: {year: moment(valueDate).format("YYYY")}})
                .then((response) => {
                    const data = response.data.result;
                    const updatedItemsMonth = itemsMonth.map(el => {
                        if (data.includes(el.numeric)) {
                            if (graphLoading) {
                                return {...el, disabled: true}
                            } else {
                                return {...el, disabled: false}
                            }
                        } else {
                            if (el.numeric === 'exp' && filterDate && !graphLoading) {
                                return {...el, disabled: false}
                            } else if (el.numeric === 'imp' && !company) {
                                return {...el, disabled: false}
                            }
                            return {...el, disabled: true}
                        }
                    });
                    setItemsMonth(updatedItemsMonth);
                });
        }
    }, [valueDate, filterDate, graphLoading]);

    const handleMonthChange = (value: any) => {
        if (value.numeric === 'exp') {
            AgExportCmp({data: rowDataTransfer})
        } else {
            setFilterDate(null)
            setValueMonthChoice(value)
            const toDate = moment(`${moment(valueDate).format("YYYY")}-${value.numeric}-01`).toDate();
            setFilterDate(toDate)
        }
    }
    const handleLoadingGraph = (loading: boolean) => {
        setGraphLoading(loading)
    }

    return (
        <>
            <Grid className="gridMain" cols="2" gap="2xs">
                <GridItem col={1}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <DatePicker
                            id='year'
                            style={{paddingRight: '5px'}}
                            // style={{zIndex: 2}}
                            size={'s'}
                            type="year"
                            value={valueDate}
                            onChange={({value}) => setValueDate(value)}
                        />
                        <div className={cnChoiceGroupExampleAdaptiveScroll()}>
                            <ChoiceGroup
                                value={valueMonthChoice}
                                onChange={({value}) => handleMonthChange(value)}
                                items={itemsMonth}
                                size={'s'}
                                getItemLabel={(item) => item.name}
                                name="ChoiceGroupExampleSomeDisabled"
                                getItemDisabled={(item: Item) => item.disabled}
                                width='full'
                                disabled={graphLoading}
                            />

                        </div>

                    </div>
                </GridItem>
                {/*<GridItem style={{display: "flex", justifySelf: "end"}}>*/}
                {/*    /!*<AgExportCmp data={rowDataTransfer}/>*!/*/}
                {/*    */}
                {/*</GridItem>*/}
                <GridItem col={2}>
                    {filterDate && <GridGraphGraphList date={filterDate} rowDataTransfer={rowDataTransfer}
                                                       setRowDataTransfer={setRowDataTransfer} loading={handleLoadingGraph}/>}
                </GridItem>
            </Grid>

        </>
    );
}

export default PageGraph;