import {Modal} from '@consta/uikit/Modal';
import {Text} from '@consta/uikit/Text';
import {SnackCmp} from "../../../component/snack.cmp";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {DatePicker} from "@consta/uikit/DatePicker";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Slider} from "@consta/uikit/Slider";
import {IconSettings} from "@consta/uikit/IconSettings";
import {Select} from "@consta/uikit/Select";
import {Button} from "@consta/uikit/Button";
import React, {useEffect, useState} from "react";
import '../../css/pageGraph.css';
import axios from "axios";
import {
    API_BSI_CLEAR_ID,
    API_BSI_SELECT, API_CLIFF_GRAPH,
    API_GRAPH_DAY,
    API_GRAPH_UPDATE, API_PPK_GRAPH,
    API_REPAIR_CHECK, API_SETTINGS_BLOCKDATE,
    API_URL_DICT
} from "../../../service/api.endpoint";
import {company, username} from "../../../component/header.cmp";
import moment from "moment/moment";
import {bsiLeveltoGraph, level} from "../../../component/level.cmp";
import cookieCmp from "../../../component/cookie.cmp";
import {Badge} from "@consta/uikit/Badge";
import {IconLineAndBarChart} from "@consta/uikit/IconLineAndBarChart";
import ModalBsiDiagram from "../../bsi/component/modal.bsi.diagram";
import {useQuery} from "@tanstack/react-query";
import {plan} from "./plan.newRecord.func";


export type Item = {
    label: string;
    id: string;
};

export type ItemBsi = {
    bsi: string;
    label: string;
    id: string;
    depth: number
    descent_start: Date
    descent_end: Date
    descent_total: string
    lift_start: Date
    lift_end: Date
    lift_total: Date
    ppk: number
    bsi_level: string
};

type DictType = {
    pk: string,
    name: string,
    fact?: number | null,
    plan?: number | null,
    comment: {
        name: string,
        pk: string
    }[],
    level: {
        name: string,
        pk: string
    }[],
    equipage: {
        name: string,
        pk: string
    }[],
}

interface ModalComponentProps {
    isOpen: boolean;
    onClose: any;
    nodeData: any
    props: any
}

// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

// проверка на клик в будущий день
function isFuture(date: string) {
    const today = moment(new Date(), 'DD.MM.YYYY');
    const formattedDate = moment(date, 'DD.MM.YYYY');
    return formattedDate > today
}

// проверка на заблокированный период
export function isBlock(date: string, days: number) {
    const formattedDate = moment(date, 'DD.MM.YYYY');
    const blockDate = moment().subtract(days, 'days')
    return formattedDate < blockDate

}

const ModalGraphNewRecord: React.FC<ModalComponentProps> = ({props, isOpen, onClose, nodeData}) => {
    const [modalOpen] = useState(isOpen);
    const [isSaving, setIsSaving] = useState(true);
    const [isDepthDisabled, setIsDepthDisabled] = useState(false);
    const [levelDisabled, setLevelDisabled] = useState(false);
    const [valueEquipage, setValueEquipage] = useState<Item | null>();
    const [valueBsi, setValueBsi] = useState<ItemBsi[] | null>();
    const [valueComment, setValueComment] = useState<Item | null>();
    const [valueStart, setValueStart] = useState<any>(null);
    const [valueEnd, setValueEnd] = useState<Date | null>(null);
    const [valueDiam, setValueDiam] = useState<string | null>(null);
    const [valueFIO, setValueFIO] = useState<string | null>(null);
    const [valuePpk, setValuePpk] = useState<string>('1000');
    const [valueLevel, setValueLevel] = useState<Item | null>();
    const [valueDepth, setValueDepth] = useState<number>(900);
    const [valuePlan, setValuePlan] = useState<number | null>();

    const [valueInterval, setValueInterval] = useState<[number, number]>([0, 0]);
    const [itemsComment, setItemsComment] = useState<Item[]>([]);
    const [itemsLevel, setItemsLevel] = useState<Item[]>([]);
    const [itemsEquipage, setItemsEquipage] = useState<Item[]>([]);
    const [itemsBsi, setItemsBsi] = useState<ItemBsi[]>([]);
    const [isAlert, setIsAlert] = useState<string | null>(null)
    const [statusEquipage, setStatusEquipage] = useState<'success' | 'alert' | undefined>(undefined)
    const [captionEquipage, setCaptionEquipage] = useState<string | undefined>(undefined)
    const colName = props.collName.split('.')[0]
    const [formData, setFormData] = useState<any>(null)
    const [oldData, setOldData] = useState<any>(null)
    const [planAtLoad, setPlanAtLoad] = useState<number | null>(null)
    const [alertText, setAlertText] = useState<string>('')
    const [bsiID, setBsiID] = useState<string | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectInfo, setSelectInfo] = useState<string>('')
    const [bsiDiagram, setBsiDiagram] = useState<any>(null)
    const [logsUpdate, setLogsUpdate] = useState<boolean | null>(null)


    const handleChangeDiam = ({value}: {
        value: string | null
    }) => setValueDiam(value);
    // const handleChangePpk = ({value}: { value: string }) => setValuePpk(value);
    const handleChangeFIO = ({value}: {
        value: string | null
    }) => setValueFIO(value);

    const fetchBlockDateAndCliffData = async () => {
        try {
            const [blockDateResponse, cliffDataResponse, ppkDataResponse] = await Promise.all([
                axios.get(API_SETTINGS_BLOCKDATE),
                axios.get(API_CLIFF_GRAPH, {
                    params: {
                        well_id: props?.well.id,
                        date: props?.date
                    }
                }),
                axios.get(API_PPK_GRAPH, {
                    params: {
                        well_id: props?.well.id,
                        date: props?.date
                    }
                })
            ]);

            return {
                block_date: blockDateResponse.data['block_date'],
                cliff_data: cliffDataResponse.data,
                ppk_data: ppkDataResponse.data
            };
        } catch (error) {
            throw new Error('Failed to fetch data');
        }
    };

    // blockDate and cliff
    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['blockDate', 'cliffData'],
        queryFn: fetchBlockDateAndCliffData
    });


    // проверка на исправность БСИ и ремонт
    const handleChangeEquipage = async (value: Item | null) => {
        setSelectInfo(`${value?.label} на ${moment(props?.date, 'DD.MM.YYYY').format('DD.MM.YYYY')}`)
        setValueEquipage(value);
        const date = moment(props?.date, 'DD.MM.YYYY').format('YYYY-MM-DD');
        // смотрим не исправные экипажи
        try {
            const repairCheckResponse = await axios.get(API_REPAIR_CHECK, {
                params: {
                    name: company,
                    equipage: value?.id,
                    date: date
                }
            });

            const repairCheckData: DictType = repairCheckResponse.data;

            // если исправен ищем бси
            if (Object.keys(repairCheckData).length < 1) {
                const bsiSelectResponse = await axios.get(API_BSI_SELECT, {
                    params: {
                        equipage: value?.id,
                        date: date,
                        action: 'bsi_file'
                    }
                });

                const bsiSelectData = bsiSelectResponse.data;
                if (Object.keys(bsiSelectData).length < 1) {
                    handleNoBsiFile();
                } else {
                    handleBsiFileFound(bsiSelectData);
                }
            } else {
                handleBsiNotWorking();
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleNoBsiFile = () => {
        setStatusEquipage('alert');
        setIsSaving(true);
        setCaptionEquipage('БСИ-файл не найден');
        setValueFIO(null);
        setItemsBsi([]);
        setValueBsi(null);
        setBsiID(null)
    }

    const handleBsiFileFound = (bsiSelectData: any) => {
        setStatusEquipage('success');
        setIsSaving(true);
        setCaptionEquipage('БСИ-файл найден');

        setValueFIO(bsiSelectData[0]?.fio);
        if (formData.bsi) {
            const graph_id = formData.bsi.map((item: any) => item.id)
            const filteredDataBsi = bsiSelectData.filter((item: any) => graph_id.includes(item.id) || item.graph === null);

            setItemsBsi(filteredDataBsi.map((item: any) => ({
                label: moment(item.descent_start).format('HH:mm:ss'),
                id: item.id,
                bsi: item.bsi,
                depth: item.depth,
                descent_start: item.descent_start,
                descent_end: item.descent_end,
                descent_total: item.descent_total,
                lift_start: item.lift_start,
                lift_end: item.lift_end,
                lift_total: item.lift_total,
                ppk: valuePpk,
                bsi_level: level(item.descent_total, item.depth, parseInt(valuePpk))
            })));
        }
    }

    const handleBsiNotWorking = () => {
        setStatusEquipage('success');
        // setValueFIO(null);
        setItemsBsi([]);
        setValueBsi(null);
        setBsiID(null)
        // setIsSaving(false);
        setCaptionEquipage('БСИ не исправно');
    }

    const handleChangeComment = (value: Item | null) => {
        setValueComment(value)
        if (value !== null && value.label !== "ППК") {
            setIsDepthDisabled(true)
            setValueDepth(900)
            setValueLevel(null)
            setValueDiam(null)
            setValueInterval([0, 0])
            // value.label === 'ЛГПП' ? setValueDepth(900) : setValueDepth(0)
        } else {
            setIsDepthDisabled(false)
        }
    }

    const handleSaveButton = async () => {
        setIsSaving(true); // Устанавливаем флаг сохранения в true
        try {
            await addGraphRecord();
        } finally {
            setIsSaving(false);
        }
    };

    const handleValueBsiChange = (value: any) => {
        if (value !== null) {
            value.sort((a: any, b: any) => {
                // Приводим значения поля "descent_start" к типу Date для сравнения
                const descentStartA = new Date(a.descent_start);
                const descentStartB = new Date(b.descent_start);
                // Сравниваем значения
                if (descentStartA < descentStartB) {
                    return -1;
                } else if (descentStartA > descentStartB) {
                    return 1;
                } else {
                    return 0;
                }
            });
            if (value.length > 1) {
                setValueLevel({
                    "id": "4d6204a7-6f49-4c74-a6e8-ad741db0c32a",
                    "label": "Тяжелая"
                })
                setLevelDisabled(true)
            } else {
                setValueLevel(null)
                setLevelDisabled(false)
            }
        }
        setBsiDiagram(value)
        if (value) {
            const lastItem = value[value.length - 1];
            setBsiID(lastItem.bsi)
        } else {
            setBsiID(null)
        }

        setValueBsi(value)
    }

    const addGraphRecord = async () => {
        const result = plan({valuePlan, valuePpk, valueBsi, valueComment, valueDepth})

        if (formData.bsi && formData.bsi[formData.bsi.length - 1].id) {
            const ids = formData.bsi.map((item: any) => item.id);
            await Promise.all(ids.map(async (el: any) => {
                await axios.put(`${API_BSI_CLEAR_ID}/${el}`, {}, {
                    headers: {
                        'X-CSRFToken': csrftoken
                    }
                });
            }));
        }
        // проставление ЛГПП исходя из level и bsi_level
        let bsi_level
        let newValueComment = null;
        if ((!valueComment || ['ЛГПП', 'ППК'].includes(valueComment.label)) && valueBsi) {
            bsi_level = bsiLeveltoGraph(valueBsi, valueLevel, parseInt(valuePpk))

            if (valueComment?.label === 'ЛГПП' && bsi_level.name !== 'Непроход' && (nodeData.data.level === 'Непроход' || nodeData.data.bsi_level === 'Непроход')) {
                newValueComment = {label: 'ЛГПП', id: 'd7675e2b-9f30-4b19-8105-800ba73499af'}
            }
        } else if ((!valueComment || valueComment.label === 'ЛГПП') && !valueBsi) {
            if (valueLevel?.label !== 'Непроход' && (nodeData.data.level === 'Непроход' || nodeData.data.bsi_level === 'Непроход')) {
                newValueComment = {label: 'ЛГПП', id: 'd7675e2b-9f30-4b19-8105-800ba73499af'}
            }
            bsi_level = null
        } else if (valueComment?.label === 'Непроход фрезы' && valueBsi) {
            bsi_level = bsiLeveltoGraph(valueBsi, valueLevel, parseInt(valuePpk))
        } else if (valueComment?.label === 'Непроход фрезы' && !valueBsi) {
            bsi_level = null
        }


        const newGraphData = {
            [colName]: {
                bsi: valueBsi,
                fio: valueFIO,
                diam: valueDiam,
                fact: result,
                plan: result,
                depth: valueDepth,
                level: valueLevel,
                comment: newValueComment ? newValueComment : valueComment,
                equipage: valueEquipage,
                interval: `${valueInterval[0]}-${valueInterval[1]}`,
                time_end: valueEnd,
                time_start: valueStart,
            },
            ['total_fact']: null,
            ['level']: valueLevel?.id,
            ['bsi_level']: bsi_level?.id ? bsi_level.id : null,
            ['value_bsi']: valueBsi,
            ['diam']: valueDiam,
            ['comment']: newValueComment ? newValueComment.id : valueComment?.id,
            ['interval']: `${valueInterval[0]}-${valueInterval[1]}`,
            ['depth']: (bsi_level?.id && valueBsi) ? Math.round(valueBsi[valueBsi?.length - 1].depth) : Math.round(valueDepth)
        };
        try {
            const response = await Promise.all([Promise.resolve(axios.put(`${API_GRAPH_UPDATE}/${nodeData.data.pk}`, {
                data: newGraphData,
                action: 'upd',
                log_data: {
                    date: props?.date,
                    deposit: nodeData?.data.deposit,
                    bush: nodeData?.data.bush,
                    wells: props?.well.label,
                    username: localStorage.getItem('username'),
                    action: logsUpdate === true ? 'GRAPH_UPDATE' : 'GRAPH_INSERT',
                    old_data: oldData
                }

            }, {
                headers: {
                    'X-CSRFToken': csrftoken
                }
            }))])


            // Передаем значение в родительский компонент
            newGraphData.total_fact = response[0].data.total_fact
            newGraphData.level = response[0].data.temp_level
            newGraphData.bsi_level = response[0].data.temp_bsi_level
            newGraphData.diam = response[0].data.temp_diam
            newGraphData.comment = response[0].data.temp_comment
            console.log(response[0].data)
            console.log(response[0].data.temp_bsi_level)
            // if (valueBsi && valueBsi[valueBsi.length - 1]?.descent_total && bsi_level) {
            //     newGraphData.bsi_level = bsi_level.name
            // }
            // newGraphData.level = valueLevel?.label
            onClose(newGraphData, 'success')
            console.log('Запись успешно добавлена:');
        } catch (error) {
            setAlertText('Произошла ошибка!')
            setIsAlert('alert');
            onClose(false, 'alert')
            console.error('Ошибка при добавлении записи:', error);
        }
    };

    // enabled/disabled save
    useEffect(() => {
        if ((!valueBsi || !valueDiam || !valueLevel || (valueInterval[0] === 0 && valueInterval[1] === 0)) && (!valueComment || valueComment.label === 'ЛГПП')) {
            setIsSaving(true);
        } else {
            setIsSaving(false);
        }
        if (captionEquipage === 'БСИ не исправно') {
            if ((!valueDiam ||
                    (valueInterval[0] === 0 && valueInterval[1] === 0) ||
                    !valueStart ||
                    !valueEnd ||
                    !valueLevel ||
                    !valueFIO) &&
                !valueComment
            ) {
                setIsSaving(true);
            } else {
                setIsSaving(false);
            }
        }
        // if (valueComment && valueComment.label !== 'ЛГПП') {
        //     setIsDepthDisabled(true)
        //     setLevelDisabled(true)
        //
        // } else {
        //     setLevelDisabled(false)
        //     setIsDepthDisabled(false)
        // }
        if (valueBsi) {
            const lastItem = valueBsi[valueBsi.length - 1];
            setBsiID(lastItem.bsi)
        }

        // блокируем изменение степени при глубине меньше ппк
        if (valuePpk && valueDepth < (parseInt(valuePpk) !== 1000 ? parseInt(valuePpk) : parseInt(valuePpk) - 100)) {
            setValueLevel({
                "id": "4d6204a7-6f49-4c74-a6e8-ad741db0c59a",
                "label": "Непроход"
            })
            setLevelDisabled(true)
        } else {

            if (valueComment && (valueComment.label !== 'ЛГПП' && valueComment.label !== 'ППК')) {
                setIsDepthDisabled(true)
                setLevelDisabled(true)
                setValueLevel(null)

            } else {
                setLevelDisabled(false)
                setIsDepthDisabled(false)
            }
        }


    }, [valueBsi, valueDiam, valueInterval, valueComment, valueEnd, valueStart, valueFIO, valueDepth]);

    useEffect(() => {

        setPlanAtLoad(nodeData.data[props.collName].plan)
        axios.get<DictType>(API_URL_DICT)
            .then((response) => {
                const data: DictType = response.data;
                setItemsComment(data.comment.map((item) => ({
                    label: item.name,
                    id: item.pk,
                })))
                setItemsLevel(data.level.map((item) => ({
                    label: item.name,
                    id: item.pk,
                })))
                setItemsEquipage(data.equipage.filter((item: any) => (username !== 'admin' ? item.org.name === company : true)).map((item) => ({
                    label: item.name,
                    id: item.pk,
                })))
            });
        axios.get(API_GRAPH_DAY, {params: {day: props.collName, id: nodeData.data.pk}})
            .then((response) => {
                const data: DictType = response.data;

                // для логов- понятие это обновление или новая запись
                if (data.fact !== null) {
                    setLogsUpdate(true)
                    setOldData(data)
                } else {
                    setLogsUpdate(false)
                }
                setFormData(data)
            });
    }, [])


    useEffect(() => {
        if (formData) {
            if (formData.interval !== null) {
                const inter = formData.interval.split('-')
                setValueInterval([parseInt(inter[0]), parseInt(inter[1])])
            } else {
                setValueInterval([0, 0])
            }
            if (formData.depth !== null) {
                setValueDepth(parseInt(formData.depth))
            } else {
                setValueDepth(900)
            }
            if (formData.time_start !== null) {
                setValueStart(new Date(formData.time_start))
            } else {
                setValueStart(null)
            }
            if (formData.time_end !== null) {
                setValueEnd(new Date(formData.time_end))
            } else {
                setValueEnd(null)
            }

            if (formData.bsi && formData.bsi[formData.bsi.length - 1].descent_start !== null) {
                setValueBsi(formData.bsi)
                setBsiDiagram(formData.bsi)
            } else {
                setValueBsi(null)
            }

            if (formData.equipage) {
                handleChangeEquipage(formData.equipage)
            }

            setValuePlan(formData.plan)

            // setValueEquipage(nodeData?.data[props.collName].equipage)
            setValueDiam(formData.diam)
            setValueLevel(formData.level)
            setValueFIO(formData.fio)
            setValueComment(formData.comment)

            setValuePpk(data?.ppk_data !== "" ? data?.ppk_data.depth : props.well.ppk)
        } else {
            setValueInterval([0, 0])
            setValueDepth(900)
        }
    }, [formData]);


    const isEndTimeEnabled = valueStart !== null;

    return (
        <>
            <Modal
                className="modal_graph"
                isOpen={modalOpen}
                hasOverlay
                // onClickOutside={onClose}
                onEsc={onClose}
            >
                {isAlert === 'alert' && <SnackCmp alertType={isAlert} alertText={alertText}/>}

                {isFuture(props?.date) ?
                    <Text size='xl' align="center" view='alert' weight='black'>{props?.date} еще не наступило! Ввод
                        данных
                        запрещен.</Text> :
                    isBlock(props?.date, parseInt(data?.block_date)) ?
                        <Text size='xl' align="center" view='alert' weight='black'>Выбранный период закрыт на
                            редактирование!</Text> :
                        <Text size='xl' weight='black'>{props?.date}</Text>}
                <Grid cols="3" gap="xs">
                    <GridItem col={"2"}>
                        <Text size='xl' font='mono'>{nodeData?.data.deposit} м/р,
                            {nodeData?.data.dns},
                            Куст: <b>{nodeData?.data.bush}</b>,
                            Скв: <b>{props?.well.label}</b></Text>
                    </GridItem>
                    <GridItem>
                    </GridItem>
                    <GridItem col={"1"}>
                        <Combobox
                            items={itemsEquipage}
                            value={valueEquipage}
                            label="Экипаж"
                            onChange={({value}) => handleChangeEquipage(value)}
                            placeholder="Выберите экипаж"
                            status={statusEquipage}
                            caption={captionEquipage}
                        />
                    </GridItem>
                    <GridItem col={"1"} className="inputElement">
                        {captionEquipage === 'БСИ не исправно' ?
                            <DatePicker
                                label="Время начала"
                                type="time"
                                value={valueStart}
                                multiplicitySeconds={0}
                                onChange={({value}) => setValueStart(value)}
                                placeholder="ЧЧ:ММ"
                            /> :

                            <Combobox
                                items={itemsBsi}
                                value={valueBsi}
                                label="Время начала работ"
                                onChange={({value}) => handleValueBsiChange(value)}
                                placeholder="Укажите время"
                                // status={statusEquipage}
                                // caption={captionEquipage}
                                multiple
                            />
                        }
                    </GridItem>
                    {captionEquipage === 'БСИ не исправно' ? (
                        <GridItem>
                            <DatePicker
                                label="Время завершения"
                                type="time"
                                value={valueEnd}
                                multiplicitySeconds={0}
                                onChange={({value}) => setValueEnd(value)}
                                placeholder="ЧЧ:ММ"
                                disabled={!isEndTimeEnabled}
                                minDate={valueStart}
                            />
                        </GridItem>
                    ) : (
                        // captionEquipage === 'БСИ-файл найден' && (
                        bsiID && (
                            <GridItem col={"1"} style={{marginLeft: '20px', marginTop: '40px'}}>
                                <Badge onClick={() => setIsModalOpen(true)} iconLeft={IconLineAndBarChart}
                                       style={{cursor: 'pointer'}}
                                       view="stroked" label="Диаграмма БСИ"/>
                            </GridItem>
                        )
                    )}
                    <GridItem col={"2"}>
                        <Slider className="inputElement"
                                value={valueDepth}
                                label={`Глубина спуска ${valueDepth}м`}
                                withTooltip
                                min={0}
                                max={1000}
                                step={1}
                                caption={valuePpk && valueDepth < (parseInt(valuePpk) !== 1000 ? parseInt(valuePpk) : parseInt(valuePpk) - 100) ? "непроход" : !isDepthDisabled ? "проход" : "Орг.причины"}
                                status={valuePpk && valueDepth < (parseInt(valuePpk) !== 1000 ? parseInt(valuePpk) : parseInt(valuePpk) - 100) ? "alert" : "success"}
                                view="default"
                                leftSide="input"
                                rightSide={IconSettings}
                                onChange={({value}) => !isDepthDisabled && setValueDepth(value)}
                                disabled={isDepthDisabled}
                        />
                    </GridItem>
                    <GridItem>
                        <TextField
                            label="Диаметр скребка"
                            onChange={handleChangeDiam}
                            value={valueDiam}
                            type="number"
                            placeholder="Укажите диаметр"
                            disabled={isDepthDisabled}
                        />
                    </GridItem>
                    <GridItem col={"3"}>
                        <Slider className="inputElement"
                                min={0}
                                max={1000}
                                step={1}
                                label={`Интервал отложений ${valueInterval[0]}м-${valueInterval[1]}м`}
                                onChange={({value}) => !isDepthDisabled && setValueInterval(value)}
                                view="default"
                                value={valueInterval}
                                leftSide="input"
                                rightSide="input"
                                disabled={isDepthDisabled}
                                range
                        />
                    </GridItem>
                    <GridItem>
                        <Select
                            items={itemsLevel}
                            value={valueLevel}
                            label="Степень сложности"
                            onChange={({value}) => setValueLevel(value)}
                            placeholder="укажите степень"
                            disabled={levelDisabled}
                        />
                    </GridItem>
                    <GridItem col={"2"}>
                        <TextField
                            style={{width: "100%"}}
                            label="ФИО"
                            onChange={handleChangeFIO}
                            value={valueFIO}
                            type="text"
                            placeholder="Укажите ФИО"
                        />
                    </GridItem>
                    <GridItem col={"2"} className="inputElement">
                        <Combobox
                            items={itemsComment}
                            value={valueComment}
                            label="Примечание"
                            onChange={({value}) => handleChangeComment(value)}
                            placeholder="Выберите примечание"
                        />
                    </GridItem>
                    <GridItem>
                        <TextField
                            // style={{width: "100%"}}
                            label="ППК"
                            // onChange={handleChangePpk}
                            value={valuePpk}
                            // type="number"
                            placeholder="м"
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem col={"3"}>
                        {data?.cliff_data.length > 0 &&
                            data?.cliff_data.map((el: any) =>
                                <Text key={el.pk} view={'warning'} fontStyle={'italic'}>
                                    Не извлеченный обрыв инструмента на глубине {el.depth}м.
                                    от {moment(el.cliff_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                                </Text>)
                        }
                    </GridItem>
                    <GridItem>
                    </GridItem>
                </Grid>
                <div className="parentComponent">
                    <Button
                        size="m"
                        view="primary"
                        label="Сохранить"
                        width="default"
                        onClick={handleSaveButton}
                        disabled={isSaving || (isFuture(props?.date) || isBlock(props?.date, data?.block_date))}  // Добавляем блокировку кнопки во время сохранения
                    />
                    <Button
                        className='button_graph___cancel'
                        size="m"
                        view="primary"
                        label="Отменить"
                        width="default"
                        onClick={() => {
                            onClose(false);
                        }}
                    />
                </div>
            </Modal>
            {isAlert === 'success' && <SnackCmp alertType={isAlert} alertText={alertText}/>}
            {isModalOpen && <ModalBsiDiagram isOpen={isModalOpen}
                                             onClose={handleModalClose}
                                             fileName={bsiID}
                                             equipageInfo={selectInfo}
                                             bsi={bsiDiagram}
            />}
        </>
    )
};

export default ModalGraphNewRecord;
