import React, {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import '../../../component/css/agGridGraph.css';
import axios from "axios";
import {API_EQUIPAGE_LIST} from "../../../service/api.endpoint";
import {Loader} from '@consta/uikit/LoaderCanary';
import {company} from "../../../component/header.cmp";
import moment from "moment";

const GridEquipageEquipageList: React.FC = () => {
    const gridRef = useRef<any>();
    const containerStyle = useMemo(() => ({width: '100%', height: '100%'}), []);
    const gridStyle = useMemo(() => ({minHeight: '90vh', width: '100%'}), []);
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const columnDefs = [
        {
            field: 'id',
            headerName: 'id',
            hide: true
        },
        {
            field: 'created_at',
            headerName: 'Добавлен',

        },
        {
            field: 'name',
            headerName: 'Гос. номер',
            sortable: true,
            filter: true,
            resizable: true,

        },
    ];

    const getRowId = useMemo(() => {
        return (params: any) => {
            return params.data.pk
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            // width: 120,
            sortable: true,
            resizable: true,
        };
    }, []);


    const fetchData = async () => {
        try {
            // console.time("fetchData"); // Засекаем время выполнения
            const response = await axios.get(API_EQUIPAGE_LIST, {params: {company: company}});
            // console.timeEnd("fetchData"); // Выводим время выполнения в консоль
            setIsLoading(false);

            return response.data.map((item: any) => {
                const formattedDate = moment(item.created_at).format('DD.MM.YYYY HH:mm');
                return {...item, created_at: formattedDate};
            })
        } catch (error) {
            console.log(error);
        }
    };

    const onGridReady = async (params: any) => {
        // const data = await fetchData()
        setRowData(await fetchData())
    };

    useEffect(() => {
        setIsLoading(true)
        onGridReady(null); // Вызываем функцию при монтировании компонента
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
            ],
        };
    }, []);

    const onFirstDataRendered = useCallback((params: any) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    return (
        <div style={containerStyle}>
            <div className="example-wrapper">
                <div style={{marginBottom: '5px'}}>
                    <span id="eMessage"></span>
                </div>

                <div style={gridStyle} className="ag-theme-alpine">
                    {isLoading ? <Loader/> :
                        // 'ПЕТУШНЯ ДЕШЕВАЯ'
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            animateRows={true}
                            getRowId={getRowId}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            statusBar={statusBar}
                            onFirstDataRendered={onFirstDataRendered}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default GridEquipageEquipageList;