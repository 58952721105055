import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import '../../../component/css/agGridGraph.css';
import axios from "axios";
import {API_PPK, API_PPK_ACT} from "../../../service/api.endpoint";
import {Loader} from '@consta/uikit/LoaderCanary';
import {CellDoubleClickedEvent} from "@ag-grid-community/core";
import {LicenseManager} from "@ag-grid-enterprise/core";
import moment from "moment/moment";
import {Button} from '@consta/uikit/Button'; // Добавьте компонент кнопки из Consta или используйте любой другой компонент кнопки

import {ModuleRegistry} from '@ag-grid-community/core';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import ModalPPKView from "./modal.ppk.ppkView";

ModuleRegistry.registerModules([ExcelExportModule]);


const license = process.env.REACT_APP_AGGRID
if (license) {
    LicenseManager.setLicenseKey(license);
}

interface RepairListInt {
    isUpdate: boolean
    isExport: boolean
}

const GridPPKList: React.FC<RepairListInt> = ({isUpdate, isExport}) => {
    const gridRef = useRef<any>();
    const containerStyle = useMemo(() => ({width: '100%', height: '100%'}), []);
    const gridStyle = useMemo(() => ({height: '85vh', minWidth: '400px'}), []);
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [dblClickParams, setDblClickParams] = useState<any>()
    const [graphParam, setGraphParam] = useState<any>()
    const [graphNode, setGraphNode] = useState<any>()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actPath, setActPath] = React.useState<string>('');

    const fetchAct = async (ppkID: string) => {
        try {
            const response = await axios.get(API_PPK_ACT, {
                params: {ppk_id: ppkID} // или другой параметр, который подходит вашему API
            });
            setActPath(response.data.act); // Установка состояния с полученными данными
        } catch (error) {
            console.error('Error fetching act:', error);
            // Обработка ошибки здесь
        }
    }

    const handleModalClose = async (value: any) => {
        setIsModalOpen(false);
        setRowData(await fetchData())
        // setGraphParam(null)
        // setGraphNode(null)
    };

    const onCellDoubleClicked = (params: CellDoubleClickedEvent) => {
        fetchAct(params.node.data.pk)
        setDblClickParams(params)
        setIsModalOpen(true)
        setGraphParam({
            collName: params.colDef.field
        })

        setGraphNode(params.node)
    }

    const columnDefs = [
        {
            field: 'id',
            headerName: 'id',
            hide: true
        },
        {
            field: 'wells.name',
            headerName: 'Cкважина',
            initialWidth: 90,
            filter: true,
        },
        {
            field: 'wells.bush.name',
            headerName: 'Куст',
            initialWidth: 100,
            enableRowGroup: true,
            filter: true,
        },
        {
            field: 'depth',
            headerName: 'Глубина',
            initialWidth: 100,
            enableRowGroup: true,
            filter: true,
        },
        {
            field: 'created_at',
            headerName: 'Добавлен',
            initialWidth: 100,
            filter: true,
            hide: true,
            enableRowGroup: true,
            valueFormatter: (params: any) => moment(params.value).add(5, 'hour').format('DD.MM.YYYY HH:mm')
        },
        {
            field: 'updated_at',
            headerName: 'Обновлен',
            initialWidth: 100,
            filter: true,
            hide: true,
            valueFormatter: (params: any) => moment(params.value).add(5, 'hour').format('DD.MM.YYYY HH:mm')
        },
        {
            field: 'ppk_date',
            headerName: 'АКТ на дату',
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params: any) => moment(params.value).format('DD.MM.YYYY')
        },
        {
            field: 'ppk_commit_date',
            headerName: 'АКТ утвержден',
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params: any) => params.value && moment(params.value).format('DD.MM.YYYY')
        },
        {
            field: 'comment',
            headerName: 'Примечание',
        },
        {
            field: 'org_ppk.name',
            filter: true,
            enableRowGroup: true,
            headerName: 'Орг.обрыв',
        },

    ];

    const getRowId = useMemo(() => {
        return (params: any) => {
            return params.data.pk
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            // width: 120,
            sortable: true,
            resizable: true,
            flex: 1
        };
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(API_PPK);
            setIsLoading(false);
            return response.data
        } catch (error) {
            console.log(error);
        }
    };

    const onGridReady = async (params: any) => {
        setRowData(await fetchData())
    };

    useEffect(() => {
        setIsLoading(true)
        onGridReady(null);
    }, []);

    useEffect(() => {
        setIsLoading(true)
        onGridReady(null);
    }, [isUpdate]);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
            ],
        };
    }, []);

    useEffect(() => {
        rowData &&
        exportToExcel()
    }, [isExport]);

    const exportToExcel = () => {
        gridRef.current.api.exportDataAsExcel();
    }

    return (
        <div style={containerStyle}>
            <div className="example-wrapper">
                <div style={gridStyle} className="ag-theme-alpine">
                    {isLoading ? <Loader/> :
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            animateRows={true}
                            getRowId={getRowId}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            statusBar={statusBar}
                            onCellDoubleClicked={onCellDoubleClicked}
                            rowGroupPanelShow={'always'}
                        />
                    }
                </div>
                <ModalPPKView isOpen={isModalOpen}
                              onClose={handleModalClose}
                              props={graphParam}
                              nodeData={graphNode}
                              actPath={actPath}/>
            </div>
        </div>
    );
};

export default GridPPKList;
