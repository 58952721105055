import {SnackBar, SnackBarItemShowProgressProp, SnackBarItemStatus} from "@consta/uikit/SnackBar";
import React, {useReducer} from "react";
import {ProcessingType} from "../page/bsi/component/modal.bsi.payload";


type Item = {
    key: number;
    message: string;
    status: SnackBarItemStatus;
    progressMode?: 'line' | 'timer';
};


function reducer(
    state: Item[],
    action: { type: 'add' | 'remove'; item: Item },
): Item[] {
    switch (action.type) {
        case 'add':
            return [...state, action.item];
        case 'remove':
            return state.filter((itemInState) => itemInState.key !== action.item.key);
    }
}

export type SnackCmpProps = {
    alertType: SnackBarItemStatus ;
    processingData?: ProcessingType[];
    alertText: string;
}
const getItemShowProgress = (item: Item) => item.progressMode;


export const SnackCmp: React.FC<SnackCmpProps> = (props) => {
    const [items, dispatchItems] = useReducer<
        React.Reducer<
            Item[],
            { type: 'add' | 'remove'; item: Item; key?: number | string }
        >
    >(reducer, []);

    const generateHandleAdd = (status: SnackBarItemStatus, progressMode?: SnackBarItemShowProgressProp) => () => {
        if (props.processingData && props.processingData.length > 0) {
            for (let i = 0; i < props.processingData.length; i++) {
                const key = i;
                status = props.processingData[i].status
                const item: Item = {
                    key,
                    message: `${props.processingData[i].status === 'alert' ? 'Ошибка в данных БСИ.' : 'Импортировано успешно.'}\n\nДата БСИ: ${props.processingData[i].data}, экипаж: ${props.processingData[i].equipage}, ФИО: ${props.processingData[i].fio}`,
                    status,
                    progressMode,
                };
                dispatchItems({type: 'add', item});
            }
        } else {
            const key = items.length + 1;
            const item: Item = {
                key,
                message: props.alertText,
                status,
                progressMode,
            };
            dispatchItems({type: 'add', item});
        }
    };


    const handleNormalAdd = generateHandleAdd(props.alertType, 'line');

    React.useEffect(() => handleNormalAdd(), []);

    return (
        <div style={{
            position: 'absolute',
            top: 10,
            right: 0,
            zIndex: 9999,
        }}>
            <SnackBar
                items={items}
                onItemClose={(item) => dispatchItems({type: 'remove', item})}
                getItemShowProgress={getItemShowProgress}
                getItemAutoClose={() => 3}
            />
        </div>
    );
};