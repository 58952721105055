import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import './login.css';
import axios from "axios";
import {API_CHECK_AUTH_URL, API_TOKEN_OBTAIN} from "../../service/api.endpoint";

// @ts-ignore
import {Alert, Box, Button, Container, TextField, Typography} from "@mui/material";


const PageLogin: FunctionComponent = () => {
    const [formUsername, setFormUsername] = useState<string | null>();
    const [formPassword, setFormPassword] = useState('');
    const [error, setError] = useState<string | null>()


    useEffect(() => {
        document.title = 'АСПО.Контроль | События';
    }, []);


    const submitHandler = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(API_TOKEN_OBTAIN, JSON.stringify({
            username: formUsername,
            password: formPassword,
        }), {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        })
            .then(async response => {
                if (response.status === 200) {
                    const {access, refresh} = response.data;
                    localStorage.setItem('accessToken', access)
                    localStorage.setItem('refreshToken', refresh)
                    setError(null);

                    // получаем данные пользователя
                    const responseUserInfo = await axios.get(API_CHECK_AUTH_URL, {
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                            'Authorization': `Bearer ${access}`,
                        },
                    });
                    if (responseUserInfo.status === 200) {
                        localStorage.setItem('company', responseUserInfo.data.data.last_name);
                        localStorage.setItem('username', responseUserInfo.data.data.username);
                    }

                } else {
                    console.log(error);
                    throw new Error(`Something went wrong: code ${response.status}`);
                }
            })
            .catch(error => {
                console.log(error);
                setError('Ошибка, подробности в консоли');
            })
            .finally(() => {
                window.location.reload(); // Обновление страницы
            });
    }

    return (
        <>
            <Container maxWidth="xs" sx={{mt: 2}}>
                <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                    Аутентификация
                </Typography>
                {error && <Alert severity="error" sx={{my: 2}}>{error}</Alert>}
                <Box component="form" onSubmit={submitHandler}>
                    <TextField
                        label="Имя пользователя"
                        variant="outlined"
                        autoComplete="login"
                        onChange={(e: any) => setFormUsername(e.target.value)}
                        sx={{mt: 1}}
                        fullWidth
                    />
                    <TextField
                        label="Пароль"
                        variant="outlined"
                        type="password"
                        autoComplete="password"
                        value={formPassword}
                        onChange={(e: any) => setFormPassword(e.target.value)}
                        sx={{mt: 3}}
                        fullWidth
                    />
                    <Button variant="contained" type="submit" sx={{mt: 3}} fullWidth>ВОЙТИ</Button>
                </Box>
            </Container>
        </>
    );
};

export {PageLogin};
