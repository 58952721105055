import {Modal} from '@consta/uikit/Modal';
import {Grid, GridItem} from "@consta/uikit/Grid";
import React, {useEffect, useState} from "react";
import '../../css/pageBsi.css';
import axios from "axios";
import cookieCmp from '../../../component/cookie.cmp';
import {API_BSI_DIAGRAM} from "../../../service/api.endpoint";
import {Button} from "@consta/uikit/Button";
import {Loader} from "@consta/uikit/LoaderCanary";
import moment from "moment";
// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';
import {useQuery} from "@tanstack/react-query";


var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export type Item = {
    label: string;
    id: string;
};

interface ModalComponentProps {
    isOpen: boolean;
    onClose: any;
    equipageInfo?: string
    fileName?: any
    bsi?: any,
    bsiFileDiagram?: any,
}


// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;


// ####################
// SORT DIAGRAM DATA
// ####################
interface Data {
    x: string;
    y: number;
}

function compareDescending(a: Data, b: Data) {
    if (a.x < b.x) {
        return -1;
    }
    if (a.x > b.x) {
        return 1;
    }
    return 0;
}

// ###################

const ModalBsiDiagram: React.FC<ModalComponentProps> = ({
                                                            fileName,
                                                            isOpen,
                                                            onClose,
                                                            equipageInfo,
                                                            bsiFileDiagram,
                                                            bsi
                                                        }) => {
    const [dataDiagram, setDataDiagram] = useState<any>(null)
    let transformedArrayDepth
    let transformedArraySpeed
    let transformedArrayTension
    let options

    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['diagramData'],
        queryFn: async () => {
            try {
                const response = await axios.get(API_BSI_DIAGRAM, {params: {fileName}});
                if (bsiFileDiagram) {
                    setDataDiagram(bsiFileDiagram);
                } else {
                    setDataDiagram(response.data);
                }
                return response.data;
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error);
                throw error;
            }
        },
    });


    const handleNextRequest = () => {
        refetch().then((r: any) => r.data)
    }

    useEffect(() => {
        // Обновление данных при следующем запросе
        handleNextRequest()
    }, [fileName]);


    if (dataDiagram && dataDiagram !== 'File not found') {
        let data = null;
        if (bsiFileDiagram) {
            const transformedArray = {
                depth: [],
                speed: [],
                tension: []
            };

            bsiFileDiagram.split('\n').slice(3).forEach((item: any) => {
                const [date, value1, value2, value3] = item.split("\t");
                const formattedDate = moment(date, "DD.MM.YYYY HH:mm:ss:SSS").format("YYYY-MM-DDTHH:mm:ss.SSS");

                const dateObject = new Date(formattedDate);

                const obj1 = {
                    x: dateObject,
                    y: Math.abs(parseInt(value1))
                };

                const obj2 = {
                    x: dateObject,
                    y: parseInt(value3) / 10
                };

                const obj3 = {
                    x: dateObject,
                    y: parseInt(value2)
                };

                // @ts-ignore
                transformedArray.depth.push(obj1);
                // @ts-ignore
                transformedArray.speed.push(obj2);
                // @ts-ignore
                transformedArray.tension.push(obj3);

            });

            data = {
                depth: transformedArray.depth.sort(compareDescending),
                speed: transformedArray.speed.sort(compareDescending),
                tension: transformedArray.tension.sort(compareDescending),
            }
        } else data = {
            depth: dataDiagram.depth.sort(compareDescending),
            speed: dataDiagram.speed.sort(compareDescending),
            tension: dataDiagram.tension.sort(compareDescending),
        }
        transformedArrayDepth = data['depth'].map((item: any) => {
            // noise filter
            if (item.y > 5000) {
                item.y = 0
            }
            let lineColor;
            if (bsi && bsi.length > 0) {
                const time = moment(item.x, 'DD.MM.YYYY HH:mm:ss')
                lineColor = bsi.some((interval: any) => {
                    const start = moment(interval.descent_start)
                    const end = moment(interval.lift_end)
                    return time.isBetween(start, end, null, '[]'); // проверяем, попадает ли время в любой из интервалов в массиве
                }) ? 'red' : '#0073BB';
            }
            return {
                ...item,
                x: moment(item.x, 'DD.MM.YYYY HH:mm:ss').toDate(),
                // lineColor: 'red'
                lineColor: bsi && bsi.length > 0 && lineColor

            };
        });
        transformedArraySpeed = data['speed'].map((item: any) => {
            // noise filter
            if (item.y > 2000) {
                item.y = 2000
            }
            return {
                ...item,
                x: moment(item.x, 'DD.MM.YYYY HH:mm:ss').toDate(),
                lineColor: '#64A8DD'

            };
        });
        transformedArrayTension = data['tension'].map((item: any) => {
            item.y = item.y * 5
            // noise filter
            if (item.y > 3000) {
                item.y = 1500
            }
            return {
                ...item,
                x: moment(item.x, 'DD.MM.YYYY HH:mm:ss').toDate(),
                lineColor: '#63ee45'

            };
        });
        options = {
            animationEnabled: true,
            exportEnabled: true,
            zoomEnabled: true,
            title: {
                text: `Диаграмма БСИ ${equipageInfo}`
            },
            axisX: {
                title: "Дата, время",
            },
            axisY: {
                title: "Глубина/скорость/натяжение",
            },
            data: [
                {
                    type: "spline",
                    showInLegend: true,
                    name: "Натяжение",
                    color: '#63ee45',
                    lineThickness: 2,
                    xValueFormatString: "DD.MM.YYYY HH:mm:ss",
                    dataPoints: transformedArrayTension
                },
                {
                    type: "spline",
                    showInLegend: true,
                    name: "Скорость",
                    color: '#64A8DD',
                    lineThickness: 1,
                    xValueFormatString: "DD.MM.YYYY HH:mm:ss",
                    dataPoints: transformedArraySpeed
                },
                {
                    type: "stackedArea",
                    showInLegend: true,
                    name: "Глубина",
                    xValueFormatString: "DD.MM.YYYY HH:mm:ss",
                    color: '#0073BB',
                    lineThickness: 4,
                    dataPoints: transformedArrayDepth
                },]
        }
    }

    const handleOnClose = () => {
        bsiFileDiagram = null
        setDataDiagram(null)
        bsi = null
        onClose()
    }

    return (
        <>
            <Modal
                className="modal_diagram"
                isOpen={isOpen}
                hasOverlay
                // onClickOutside={onClose}
                onEsc={onClose}
            >
                <Grid gap="xs">
                    {isPending || !dataDiagram ? <Loader/> :
                        <GridItem>
                            {dataDiagram && dataDiagram !== 'File not found' &&
                                <CanvasJSChart options={options}/>
                            }
                            {dataDiagram === 'File not found' && 'НЕТ ДАННЫХ ДЛЯ ЭТОГО БСИ'}
                        </GridItem>
                    }
                    <GridItem>
                        <div style={{position: 'absolute', right: '0px'}}>
                            <Button
                                className='cancel'
                                size="m"
                                view="primary"
                                label="Закрыть"
                                width="default"
                                onClick={handleOnClose}
                            />
                        </div>
                    </GridItem>
                </Grid>
            </Modal>
        </>
    )
};

export default ModalBsiDiagram;
