import {Modal} from '@consta/uikit/Modal';
import {Text} from '@consta/uikit/Text';
import {SnackCmp} from "../../../component/snack.cmp";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {DatePicker} from "@consta/uikit/DatePicker";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Button} from "@consta/uikit/Button";
import React, {useEffect, useState} from "react";
// @ts-ignore
import _ from "lodash";
import axios from "axios";
import {
    API_DOWNLOAD_ACT,
    API_PPK,
    API_URL_WELL
} from "../../../service/api.endpoint";
import {company, username} from "../../../component/header.cmp";
import moment from "moment/moment";
import cookieCmp from '../../../component/cookie.cmp';
import {IconSettings} from "@consta/uikit/IconSettings";
import {Slider} from "@consta/uikit/Slider";
import {DragNDropField} from "@consta/uikit/DragNDropField";
import {IconThumbUp} from "@consta/uikit/IconThumbUp";
import {v4 as uuidv4} from "uuid";

export type Item = {
    label: string;
    id: string;
    pk?: string;
};


interface DownloadButtonProps {
    filePath: string;
}

interface ModalComponentProps {
    isOpen: boolean;
    onClose: any;
    nodeData: any;
    props: any;
    actPath?: any;
}

// Получение CSRF токена из cookies
const csrftoken = cookieCmp('csrftoken');

// Установка CSRF токена в заголовок запроса
axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

const ModalPPKView: React.FC<ModalComponentProps> = ({props, isOpen, onClose, nodeData, actPath}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [valueWells, setValueWells] = useState<Item | null>();
    const [valueComment, setValueComment] = useState<string>('');
    const [valueFIO, setValueFIO] = useState<string | null>(null);
    const [itemsWells, setItemsWells] = useState<Item[]>([]);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [isAlert, setIsAlert] = useState<string | null>(null)
    const [alertText, setAlertText] = useState<string>('')
    const [valueDepth, setValueDepth] = useState<number>(0);
    const [inputValue, setInputValue] = useState<string | undefined | null>();
    const [files, setFiles] = React.useState<File[]>([]);


    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleSaveButton = async () => {
        setIsSaving(true); // Устанавливаем флаг сохранения в true
        try {
            await addPPK();
        } finally {
            setIsSaving(false);
        }
        clearData()
    };

    const handleCloseButton = () => {
        clearData()
        onClose(false);

    }

    function clearData() {
        setValueWells(null)
        setValueFIO(null)
        setValueComment('')
        setStartDate(null)
        setEndDate(null)
        setValueDepth(0)
        setFiles([])
    }

    const handleChangeComment = ({value}: { value: any }) => setValueComment(value);

    const addPPK = async () => {
        const formData = new FormData();
        const uuid = uuidv4();
        valueWells && valueWells.pk && formData.append('wells', valueWells.pk);
        company && formData.append('org_ppk', company);
        formData.append('ppk_date', nodeData ? nodeData.data?.date_from : startDate && moment(startDate.toLocaleString("ru-RU", {timeZone: "Asia/Yekaterinburg"}),
            'DD.MM.YYYY, HH:mm:ss').format('YYYY-MM-DD'));
        // company && formData.append('org', company);
        formData.append('ppk_commit_date', nodeData && nodeData.data?.date_to ? nodeData.data.date_to : endDate && moment(endDate.toLocaleString("ru-RU", {timeZone: "Asia/Yekaterinburg"}),
            'DD.MM.YYYY, HH:mm:ss').format('YYYY-MM-DD'));
        formData.append('comment', valueComment);
        formData.append('depth', valueDepth.toString());
        formData.append('ppk_id', nodeData?.data?.wells && nodeData.data.pk);
        // @ts-ignore
        formData.append('username', localStorage.getItem('username'));

        if (nodeData?.data?.wells) {
            try {
                const response = await axios.put(`${API_PPK}`, formData);
                // закрыть модальное окно
                setAlertText('Запись успешно обновлена!')
                setIsAlert('success');
                clearData()
                onClose(formData);
                console.log('Запись успешно обновлена:', response.data);
            } catch (error: any) {
                setAlertText('Ошибка при обновлении записи!')
                setIsAlert('alert');
                onClose(false);
                console.error('Ошибка при обновлении записи:', error.response.data);
            }
        } else {
            formData.append('act', files[0], `${uuid}.pdf`);
            try {
                const response = await axios.post(`${API_PPK}`, formData, {
                    headers: {
                        'X-CSRFToken': csrftoken
                    }
                });
                // закрыть модальное окно
                setAlertText('Запись успешно добавлена!')
                setIsAlert('success');
                clearData()
                onClose(formData);
                console.log('Запись успешно добавлена:', response.data);
            } catch (error: any) {
                setAlertText('Ошибка при добавлении записи!')
                setIsAlert('alert');
                onClose(false);
                console.error('Ошибка при добавлении записи:', error.response.data);
            }
        }
    };


    const fetchWells = async (inputValue: any) => {
        try {
            const response = await axios.get(API_URL_WELL, {
                params: {search: inputValue} // или другой параметр, который подходит вашему API
            });
            setItemsWells(response.data); // Установка состояния с полученными данными
        } catch (error) {
            console.error('Error fetching well list:', error);
            // Обработка ошибки здесь
        }
    };

    // const fetchAct = async (ppkID: string) => {
    //     try {
    //         const response = await axios.get(API_PPK_ACT, {
    //             params: {ppk_id: ppkID} // или другой параметр, который подходит вашему API
    //         });
    //         setActPath(response.data.act); // Установка состояния с полученными данными
    //     } catch (error) {
    //         console.error('Error fetching act:', error);
    //         // Обработка ошибки здесь
    //     }
    // }


    // обновление списка скважин по вводу в поле
    useEffect(() => {
        if (inputValue && inputValue.length > 0) {
            fetchWells(inputValue);
        } else {
            setItemsWells([]);
        }
    }, [inputValue]);

    const handleWellChange = (value: any) => {
        setInputValue(value)
        setValueWells(value);
        setStartDate(null)
        setEndDate(null)
    };

    useEffect(() => {
        if (nodeData?.data?.comment) {
            setValueComment(nodeData.data.comment)
        }
    }, [nodeData, props?.collName]);


    useEffect(() => {
        if (nodeData?.data.wells) {
            if (nodeData?.data.ppk_commit_date) {
                setIsSaving(true)
            } else {
                !endDate ? setIsSaving(true) : setIsSaving(false)
            }
        } else {
            if (!startDate || !valueWells || !valueDepth || files.length === 0) {
                setIsSaving(true);
            } else {
                setIsSaving(false);
            }
        }
    }, [startDate, endDate, valueWells, valueComment, files]);

    const handleDateChange = async (date: any, action: string) => {
        if (action === 'from') {
            setStartDate(date)
        } else {
            setEndDate(date)
        }
    }

    const handleDropFiles = (files: any) => {
        setFiles(files)
        setIsModalOpen(true)
    }


    const DownloadButton = async () => {
        try {
            const response = await axios.get(API_DOWNLOAD_ACT, {
                params: {ppk_id: nodeData.data.pk},
                responseType: 'blob', // important to handle binary data
            });

            // Create a URL for the downloaded file and click it programmatically to start the download
            const ppkDate = moment(nodeData.data.ppk_date).format('DD_MM_YYYY')
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `АКТ_ППК_от_${ppkDate}_${nodeData.data.wells.label}.pdf`); // or use ppk.act.name if you can pass it to the frontend
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

    useEffect(() => {
        console.log(actPath)
        console.log(files)
    }, [actPath, files]);

    return (
        <>
            <Modal
                className="modal"
                isOpen={isOpen}
                hasOverlay
                // onClickOutside={onClose}
                onEsc={onClose}
            >
                {isAlert === 'alert' && <SnackCmp alertType={isAlert} alertText={alertText}/>}
                <Grid cols="3" gap="xs">
                    {!nodeData?.data.wells ?
                        <>
                            <GridItem col={"2"}>
                                <Combobox
                                    items={itemsWells}
                                    value={valueWells}
                                    label="Скважина"
                                    onChange={({value}) => handleWellChange(value)}
                                    placeholder="Выберите скважину"
                                    onInputChange={({value}) => setInputValue(value)}
                                />
                            </GridItem>
                            <GridItem>
                                <DatePicker label='Дата акта ППК' value={startDate}
                                            onChange={({value}) => handleDateChange(value, 'from')}
                                            disabled={!valueWells}/>
                            </GridItem>
                            <GridItem col={"3"}>
                                <GridItem col={"3"}>
                                    <Slider className="inputElement"
                                            value={valueDepth}
                                            label={`Глубина ${valueDepth}м`}
                                            withTooltip
                                            min={0}
                                            max={1000}
                                            step={1}
                                            view="default"
                                            leftSide="input"
                                            rightSide={IconSettings}
                                            onChange={({value}) => setValueDepth(value)}

                                    />
                                </GridItem>

                            </GridItem>
                        </> :
                        <GridItem col={'3'}>
                            <Text size='xl'
                                  align={'right'}
                                  font='mono'><b>{moment(nodeData.data.ppk_date).format('DD.MM.YYYY')}</b></Text>
                            <Text align={'center'} size='xl' font='mono'> <b>АКТ</b><br/>об изменении глубины ППК <br/>
                                скв.:<b>{nodeData.data.wells.name} (кп.№{nodeData.data.wells.bush.name})</b>,
                                глубина: <b>{nodeData.data.depth}м.</b></Text>
                        </GridItem>
                    }

                    {/*COMMENT*/}
                    <GridItem col={'2'}>
                        <TextField
                            onChange={handleChangeComment}
                            value={valueComment}
                            type="textarea"
                            cols={200}
                            rows={6}
                            placeholder="Примечание"
                            disabled={nodeData?.data.wells}
                        />
                    </GridItem>
                    <GridItem col={1}>
                        {files.length === 0 && (!actPath || actPath === '') ?
                            <DragNDropField multiple={false}
                                            onDropFiles={(files) => handleDropFiles(files)}
                                            accept={['.pdf']}>
                                {({openFileDialog}) => (
                                    <>
                                        <Text weight="black">
                                            АКТ
                                        </Text>
                                        <br/>
                                        <Button size='s' onClick={openFileDialog} label="Прикрепить"/>
                                    </>
                                )}
                            </DragNDropField> :
                            <Button onClick={DownloadButton} label={'Скачать АКТ'}/>
                        }
                    </GridItem>

                    <GridItem col={'3'}>
                        <Grid cols={4} colGap={'xs'}>
                            <GridItem col={'4'}>
                                <Text view='ghost'>Дата утверждения</Text>
                            </GridItem>
                            <GridItem col={'1'}>
                                {nodeData?.data?.ppk_commit_date ?
                                    <Text
                                        font='mono'>{moment(nodeData.data.ppk_commit_date).format('DD.MM.YYYY')}</Text> :
                                    localStorage.getItem('username') === 'admin' ?
                                        <DatePicker value={endDate}
                                                    onChange={({value}) => handleDateChange(value, 'to')}
                                                    minDate={moment(nodeData?.data && nodeData.data.ppk_date, 'YYYY-MM-DD').toDate()}
                                                    disabled={localStorage.getItem('username') !== 'admin'}
                                        /> :
                                        <Text font='mono' view={'alert'}>НЕ УТВЕРЖДЕН</Text>
                                }
                            </GridItem>
                            <GridItem col={1}>
                                {localStorage.getItem('username') === 'admin' && !nodeData?.data.ppk_commit_date &&
                                    <Button
                                        size="m"
                                        view="primary"
                                        label="Утвердить АКТ"
                                        iconLeft={IconThumbUp}
                                        width="default"
                                        onlyIcon={true}
                                        onClick={handleSaveButton}
                                        disabled={isSaving} // Добавляем блокировку кнопки во время сохранения

                                    />}
                            </GridItem>
                            <GridItem col={'2'}>
                                <div style={{position: 'absolute', right: '20px'}}>
                                    {!nodeData?.data.wells &&
                                        <Button
                                            size="m"
                                            view="primary"
                                            label="Сохранить"
                                            width="default"
                                            onClick={handleSaveButton}
                                            disabled={isSaving} // Добавляем блокировку кнопки во время сохранения

                                        />}
                                    <Button
                                        className='button__cancel'
                                        size="m"
                                        view="primary"
                                        label="Отменить"
                                        width="default"
                                        onClick={handleCloseButton}
                                    />
                                </div>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </Modal>
            {
                isAlert === 'success' && <SnackCmp alertType={isAlert} alertText={alertText}/>
            }
        </>
    )
};

export default ModalPPKView;
