import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/uikit/IconAdd";
import React, {useEffect, useState} from "react";
import "../css/pageEquipage.css";
import {Sidebar} from '@consta/uikit/Sidebar';
import GridEquipageEquipageList from "./component/grid.equipage.equipageList";
import {TextField} from "@consta/uikit/TextField";
import GridEquipageRepairList from "./component/grid.equipage.repairList";
import ModalEquipageNewRepair from "./component/modal.equipage.newRepair";

const PageEquipage = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        document.title = 'АСПО.Контроль | Экипаж';
    }, []);

    const handleModalClose = (value: boolean) => {
        setIsModalOpen(false);
        setIsUpdate(value)
        // setReturnedValue(value);
    };
    return (
        <>
            <Grid className="gridMain" cols="1" gap="xs">
                <GridItem>
                    {localStorage.getItem('username') !== 'admin' &&
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Button label="Добавить ремонт БСИ"
                                    iconRight={IconAdd}
                                    onlyIcon size='s'
                                    onClick={() => setIsModalOpen(true)}
                            />
                            <Button
                                size="s"
                                view="primary"
                                label="Список экипажей"
                                width="default"
                                onClick={() => setIsSidebarOpen(true)}
                            />
                        </div>
                    }
                </GridItem>
                <GridItem>
                    <GridEquipageRepairList isUpdate={isUpdate}/>
                </GridItem>
            </Grid>
            <Sidebar
                size={'1/3'}
                isOpen={isSidebarOpen}
                onClickOutside={() => setIsSidebarOpen(false)}
                onEsc={() => setIsSidebarOpen(false)}
            >
                <div style={{
                    width: '85%',
                    maxHeight: '40%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingTop: '30px',
                    paddingBottom: '15px'
                }}>
                    <GridEquipageEquipageList/>
                </div>
                {/*<div style={{*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*}}>*/}
                {/*    <TextField*/}
                {/*        placeholder="Гос. номер"*/}
                {/*        form="roundClear"*/}
                {/*        size={'s'}*/}
                {/*        style={{*/}
                {/*            width: '100%', maxWidth: '180px', paddingLeft: '30px',*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <Button label="Добавить"*/}
                {/*            iconRight={IconAdd}*/}
                {/*            size='s'*/}
                {/*        // view='secondary'*/}
                {/*            form='brickRound'*/}
                {/*        // onClick={() => setIsModalOpen(true)}*/}
                {/*            style={{marginRight: '30px'}}/>*/}
                {/*</div>*/}
            </Sidebar>
            <ModalEquipageNewRepair isOpen={isModalOpen}
                                    onClose={handleModalClose}
                                    props={null}
                                    nodeData={null}/>

        </>
    )
}

export default PageEquipage;